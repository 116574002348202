import {MOBILE_WINDOW_LANDSCAPE_WIDTH, TABLET_WINDOW_LANDSCAPE_WIDTH} from '../const/window';
import {ChangeDetectorRef} from '@angular/core';
import {Sort} from '../models/loadable.model';
import {interval, of, Subject} from 'rxjs';
import {delay, first} from 'rxjs/operators';
import {cloneDeep, isArray, isEqual, isObject, transform} from 'lodash';
import {UserRole} from '../../store/user/user.model';
import {PermissionService} from '../services/permission.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {OverlayService} from '../services/overlay.service';
import {DeviceStatus} from "../const/types";

export const isTabletLandscapeWindow = (): boolean => {
  return window.innerWidth <= TABLET_WINDOW_LANDSCAPE_WIDTH;
};

export const isMobileLandscapeWindow = (): boolean => {
  return window.innerWidth <= MOBILE_WINDOW_LANDSCAPE_WIDTH;
};

export function detectChanges(cdf: ChangeDetectorRef): void {
  if (!cdf['destroyed']) {
    cdf.detectChanges();
  }
}

export const sortQuery = (sort: { key: string, value: string }[]): string[] => {
  return sort.filter(field => field.value !== null).map(field => `${field.key},${Sort[field.value]}`);
};


export const numberMapper = (a: number, inMin: number, inMax: number, outMin: number, outMax: number): number =>
  ((a - inMin) * (outMax - outMin) / (inMax - inMin) + outMin);

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base): {} {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
}




export function getFileReader(): FileReader {
  const fileReader = new FileReader();
  const zoneOriginalInstance = (fileReader as any).__zone_symbol__originalInstance;
  return zoneOriginalInstance || fileReader;
}

export function fileToBase64(file: Blob) {
  return new Promise<string>((resolve, reject) => {
    const fileReader = getFileReader();
    fileReader.onload = () => resolve(fileReader.result as string);
    fileReader.onerror = (err) => reject(err);
    fileReader.readAsDataURL(file);
  });
}

export function getAvailableUserRoles(permissionService: PermissionService): UserRole[] {
  const roles: UserRole[] = [
    UserRole.CompanyAdmin,
    UserRole.CompanyManager,
    UserRole.CompanyEngineer,
    UserRole.CompanyUser,
  ];

  if (permissionService.isIotUser()) {
    roles.unshift(...[
      UserRole.GPOAdmin,
      UserRole.GPOManager,
      UserRole.GPOEngineer,
      UserRole.GPOUser,
    ]);
  }

  return roles;
}

export function getUniqueItems<T extends { id: number }>(array: T[]): T[] {
  const setObj = new Set();

  return array.reduce((acc, item) => {
    if (!setObj.has(item.id)) {
      setObj.add(item.id);
      acc.push(item);
    }
    return acc;
  }, []);
}

export function intersect(a, b) {
  const setB = new Set(b);
  return [...new Set(a)].filter(x => setB.has(x));
}

export function noWhitespaceValidator(control: UntypedFormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : {whitespace: true};
}



export const EMAIL_VALIDATORS = [Validators.email, Validators.required, Validators.minLength(5)];

export function setOverlayNotVisibleWithDelay(overlayService: OverlayService): void {
  const overlayDelay = 1000; // ms
  interval(overlayDelay)
    .pipe(first())
    .subscribe(() => overlayService.setIsOverlayVisible(false));
}


export const getDeviceStatusBadgeType = (status: DeviceStatus ): string => {
  if (status === DeviceStatus.CONNECTED ) {
    return 'success';
  }
  if (status === DeviceStatus.DISCONNECTED ) {
    return 'error';
  }
  if (status === DeviceStatus.PENDING) {
    return 'default';
  }
  if (status ) {
    return 'warning';
  }
};

export const openInNewTab = (url: string): void => {
  window.open(url, '_blank');
}
