import {Injectable} from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from '../../store/auth/auth.state';
import {map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoggedGuard  {
  constructor(private store: Store, private router: Router) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(AuthState.loggedIn)
      .pipe(
        tap(isLoggedIn => {
          if (isLoggedIn) {
            this.router.navigate(['/pages', 'dashboard']);
          }
        }),
        map(isLoggedIn => !isLoggedIn),
      );
  }

}
