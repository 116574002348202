abstract class Recipient {
  readonly type: string;

  protected constructor(type: string) {
    this.type = type;
  }
}

export class ThingRecipient extends Recipient {
  readonly serial: string;

  constructor(serial: string) {
    super('THING');
    this.serial = serial;
  }
}

export class GroupRecipient extends Recipient {
  readonly id: number;

  constructor(id: number) {
    super('GROUP');
    this.id = id;
  }
}

export class BuildingRecipient extends Recipient {
  readonly id: number;

  constructor(id: number) {
    super('BUILDING');
    this.id = id;
  }
}

export interface IRecipient {
  recipient: Recipient;
}
