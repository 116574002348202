export const LANGUAGE = 'iot_hub_lang';
export const TOKEN = 'iot_hub_user_token';
export const REFRESH_TOKEN = 'iot_hub_user_refresh_token';

export const TABLE_PAGES = [10, 25, 50, 100];

export const QuillConfiguration = {
  toolbar: [
    ['image'],
    ['video']
  ],
}

export enum TypeOfDeleted {
  MODEL = "model",
  MODEL_TYPE = "modelType"
}

export const mainDateTimeFormat = 'HH:mm d.MM.y'
