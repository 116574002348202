export interface LoadableStateModel {
  loading: boolean;
}

export interface Page {
  content: any[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean
  };
  totalElements: number;
  totalPages: number;
}

export enum Sort {
  ascend= 'asc',
  descend = 'desc'
}
