import {DEFAULT_FILTERS} from './company/company.model';

export const DEFAULT_COMPANY_STATE = {
  total: 0,
  page: 1,
  companiesPicker: [],
  companies: [],
  queryParams: DEFAULT_FILTERS,
  defaultQueryParams: DEFAULT_FILTERS,
  info: null,
  showCompanyPicker: false,
  allowSelectAll: false,
  size: 25,
  selectedCompanyId: null,
  loading: true,
  companyPageLoading: false,
};
