import {LoadableStateModel} from '../../core/models/loadable.model';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {PageableStateModel} from '../../core/models/pageable.model';
import {StoreState} from '../store.state';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';
import {UserActions} from './user.action';
import {DutyEmployee, DutyPerson, IEmployee, IUser, UsersInfo} from './user.model';
import {catchError, first, tap} from 'rxjs/operators';
import {Pagination} from '../../core/models/request';
import produce from 'immer';
import {ServerHealthyService} from 'src/app/core/services/server-healthy.service';
import {Observable} from 'rxjs';
import {ErrorMessageService} from '../../core/services/error-message.service';

export interface UserStateModel extends LoadableStateModel, PageableStateModel {
  dutyPersons: DutyEmployee[];
  employees: IEmployee[];
  info?: UsersInfo;
  dutyPerson?: DutyPerson;
  users: any[];
  account: IUser;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    total: 0,
    page: 1,
    employees: [],
    users: [],
    dutyPersons: [],
    info: null,
    dutyPerson: null,
    size: 10,
    loading: true,
    account: null
  },
})
@Injectable({providedIn: 'root'})
export class UserState extends StoreState<UserStateModel> {

  constructor(
    private userService: UserService,
    protected notificationService: NzNotificationService,
    protected translateService: TranslateService,
    protected serverHealthyService: ServerHealthyService,
    protected errorMessageService: ErrorMessageService,
  ) {
    super(notificationService, translateService, serverHealthyService, errorMessageService);
  }

  @Selector()
  static loading(state: LoadableStateModel) {
    return state.loading;
  }

  @Selector()
  static account(state: UserStateModel) {
    return state.account;
  }

  @Selector()
  static info(state: UserStateModel) {
    return state.info;
  }

  @Selector()
  static dutyPerson(state: UserStateModel) {
    return state.dutyPerson;
  }

  @Selector()
  static dutyPersons(state: UserStateModel) {
    return state.dutyPersons;
  }

  @Selector()
  static employees(state: UserStateModel) {
    return state.employees;
  }

  @Action(UserActions.InfoFetched)
  private async loaded({patchState}: StateContext<UserStateModel>, {info}: UserActions.InfoFetched) {
    patchState({info, loading: false});
  }

  @Action(UserActions.OnDutyPersonFetch)
  async load(ctx: StateContext<UserStateModel>) {
    ctx.patchState({loading: true});

    this.userService.fetchDutyPerson()
      .pipe(first())
      .subscribe(
        response => ctx.dispatch(new UserActions.OnDutyPersonFetched(response)),
        (e) => this.errorHandler(e, ctx),
      );
  }

  @Action(UserActions.OnDutyPersonFetched)
  private async dutyPersonFetched(ctx: StateContext<UserStateModel>, {dutyPerson}: UserActions.OnDutyPersonFetched) {
    ctx.patchState({dutyPerson, loading: false});
  }

  @Action(UserActions.FetchPersons)
  async fetchPersons(ctx: StateContext<UserStateModel>, {name, page}: UserActions.FetchPersons) {
    ctx.patchState({loading: true, page});
    const {size} = ctx.getState();

    this.userService.fetchPersons({name}, {page, size} as Pagination)
      .pipe(first())
      .subscribe(
        response => ctx.dispatch(new UserActions.PersonsFetched(response)),
        (e) => this.errorHandler(e, ctx),
      );
  }

  @Action(UserActions.PersonsFetched)
  private async personsFetched(ctx: StateContext<UserStateModel>, {page}: UserActions.PersonsFetched) {
    ctx.patchState({...page, loading: false});
  }

  @Action(UserActions.UpdateOnDutyPerson)
  async updateOnDutyPerson(ctx: StateContext<UserStateModel>, {payload}: UserActions.UpdateOnDutyPerson) {
    ctx.patchState({loading: true});

    this.userService.updateOnDutyPerson(payload)
      .pipe(first())
      .subscribe(
        () => ctx.dispatch(new UserActions.OnDutyPersonUpdated(payload)),
        (e) => this.errorHandler(e, ctx),
      );
  }

  @Action(UserActions.OnDutyPersonUpdated)
  private async onDutyPersonUpdated(ctx: StateContext<UserStateModel>, {payload}: UserActions.OnDutyPersonUpdated) {
    const updatedState = produce(ctx.getState(), draft => {
      const dutyIndex = draft.dutyPersons.findIndex(dutyPerson => dutyPerson.id === payload.employeeId);
      if (dutyIndex !== -1) {
        draft.dutyPersons[dutyIndex] = {...draft.dutyPersons[dutyIndex], from: payload.from, until: payload.until};
      }
      draft.loading = false;
    });

    ctx.patchState(updatedState);
  }

  @Action(UserActions.CreateOnDutyPerson)
  async createOnDutyPerson(ctx: StateContext<UserStateModel>, {payload}: UserActions.CreateOnDutyPerson) {
    ctx.patchState({loading: true});

    this.userService.createOnDutyPerson(payload)
      .pipe(first())
      .subscribe(
        response => ctx.dispatch(new UserActions.OnDutyPersonCreated(response)),
        (e) => this.errorHandler(e, ctx),
      );
  }

  @Action(UserActions.OnDutyPersonCreated)
  private async onDutyPersonCreated(ctx: StateContext<UserStateModel>, {payload}: UserActions.OnDutyPersonCreated) {
    const updatedState = produce(ctx.getState(), draft => {
      draft.dutyPersons.push(payload);
      draft.dutyPerson = {
        address: !payload.employee.user.profile ? null : payload.employee.user.profile.address,
        avatar: !payload.employee.user.profile ? null : payload.employee.user.profile.avatar,
        email: payload.employee.user.email,
        firstName: payload.employee.user.firstName,
        userId: payload.employee.user.id,
        lastName: payload.employee.user.lastName,
        phone: !payload.employee.user.profile ? null : payload.employee.user.profile.phone,
        from: payload.from,
        until: payload.until,
        position: payload.employee.position
      };
      draft.loading = false;
    });
    ctx.patchState(updatedState);
  }


  @Action(UserActions.DeleteOnDutyPerson)
  private async deleteOnDutyPerson(ctx: StateContext<UserStateModel>, {dutyPersonId}: UserActions.DeleteOnDutyPerson) {
    ctx.patchState({loading: true});

    return this.userService.deleteOnDutyPerson(dutyPersonId)
      .pipe(
        first(),
      ).subscribe(
        () => ctx.dispatch(new UserActions.DeleteOnDutyPersonDeleted(dutyPersonId)),
        (e) => this.errorHandler(e, ctx)
      );
  }

  @Action(UserActions.DeleteOnDutyPersonDeleted)
  private async DeleteOnDutyPersonDeleted(ctx: StateContext<UserStateModel>, {dutyPersonId}: UserActions.DeleteOnDutyPersonDeleted) {

    const updatedState = produce(ctx.getState(), draft => {
      draft.dutyPersons = draft.dutyPersons.filter(dutyPerson => dutyPerson.id !== dutyPersonId);
      draft.dutyPerson = null;
      draft.loading = false;
    });

    ctx.patchState(updatedState);
  }

  @Action(UserActions.EmployeesFetch)
  async employeesFetch(ctx: StateContext<UserStateModel>,
                       {name, userId, employeeForReport, companyId}: UserActions.EmployeesFetch) {
    ctx.patchState({loading: true});

    this.userService.fetchEmployees({name, userId}, employeeForReport, companyId)
      .pipe(first())
      .subscribe(
        response => ctx.dispatch(new UserActions.EmployeesFetched(response)),
        (e) => this.errorHandler(e, ctx),
      );
  }

  @Action(UserActions.EmployeesFetched)
  private async employeesFetched(ctx: StateContext<UserStateModel>, {employees}: UserActions.EmployeesFetched) {
    ctx.patchState({employees, loading: false});
  }

  @Action(UserActions.LoadAccount)
  loadAccount(ctx: StateContext<UserStateModel>): Observable<any> {
    ctx.patchState({loading: true});

    return this.userService.loadAccount()
      .pipe(
        tap((account) => ctx.dispatch(new UserActions.AccountLoaded(account))),
        catchError(error => this.errorHandler(error, ctx)),
      );
  }

  @Action(UserActions.AccountLoaded)
  private async accountLoaded(ctx: StateContext<UserStateModel>, {account}: UserActions.AccountLoaded) {
    ctx.patchState({account, loading: false});
  }

  @Action(UserActions.UpdateAccount)
  updateAccount(ctx: StateContext<UserStateModel>, {payload, avatar}: UserActions.UpdateAccount): Observable<any> {
    ctx.patchState({loading: true});

    return this.userService.updateAccount(payload, avatar)
      .pipe(
        tap((account) => ctx.dispatch(new UserActions.AccountUpdate(account))),
        catchError(error => this.errorHandler(error, ctx)),
      );
  }

  @Action(UserActions.AccountUpdate)
  private async accountUpdate(ctx: StateContext<UserStateModel>, {account}: UserActions.AccountLoaded) {
    this.notificationService.success(
      this.translateService.instant('notification.success'),
      this.translateService.instant('messages.profile-updated'),
    );
    ctx.patchState({account, loading: false});
  }
}
