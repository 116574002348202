import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RequestService} from '../../core/services/request.service';
import {Observable, of} from 'rxjs';
import {
  CreateOnDutyData,
  DutyEmployee,
  DutyPerson,
  IEmployee,
  IUser,
  IUserUpdatePayload,
  UpdateOnDutyData
} from './user.model';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {HttpHelperService} from '../../core/services/http.service';
import {Pagination} from '../../core/models/request';
import {RecordModel} from '../../core/models/record.model';
import {ImgSize} from '../company/company.model';
import {environment} from '../../../environments/environment';
import {fileToBase64} from '../../core/helpers/common.helper';
import {PermissionService} from '../../core/services/permission.service';

export interface DutyPersonPage {
  dutyPersons: DutyEmployee[];
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class UserService extends RequestService {

  constructor(
    private httpClient: HttpClient,
    private httpHelperService: HttpHelperService,
    private permissionService: PermissionService,
  ) {
    super();
  }
  private readonly dutyPersonUrl = this.createUrl('api/vendor/v2/dashboard/on-duty');
  private readonly employeesFilterUrl = this.createUrl('api/v2/employees/filter');
  private readonly employeesFilterForAlertUrl = this.createUrl('api/v2/employees/alert');
  private readonly dutyPersonsUrl = this.createUrl('api/vendor/v1/employee-on-duties');
  private readonly onDutyPersonsV2Url = this.createUrl('api/v2/employee-on-duties');
  private readonly accountUrl = this.createUrl('api/account');
  private readonly avatarUrl = this.createUrl('api/photos/avatar');
  private readonly accountAvatarUrl = this.createUrl('api/avatar/previews');
  private readonly dutyPersonsByIdUrl = (id: DutyEmployee['id']) =>  `${this.createUrl('api/vendor/v1/employee-on-duties')}/${id}`;

  fetchDutyPerson(): Observable<DutyPerson | null> {
    return this.httpClient.get<DutyPerson | null>(this.dutyPersonUrl)
      .pipe(catchError(() => of(null)));
  }

  fetchEmployees(
    data: { name: string, userId?: RecordModel['id'] },
    employeeForReport: boolean = false, companyId?: RecordModel['id']): Observable<IEmployee[]> {
    let requestUrl: string = this.employeesFilterUrl;

    if (!data.userId) {
      delete data.userId;
    }

    if (employeeForReport && !data.userId && this.permissionService.isIotUser()) {
      requestUrl = this.employeesFilterForAlertUrl;
    }

    const params = this.httpHelperService.createRequestParams(data);

    if (typeof companyId !== 'undefined') {
      const headers = this.httpHelperService.createRequestHeaders({ ActiveCompany: companyId.toString() });
      return this.httpClient.get<IEmployee[]>(requestUrl, {params, headers});
    } else {
      return this.httpClient.get<IEmployee[]>(requestUrl, {params});
    }
  }

  fetchPersons(queryParams: any, pagination: Pagination): Observable<DutyPersonPage> {
    pagination.page--;
    const params = this.httpHelperService.createRequestParams({...queryParams, ...pagination});

    return this.httpClient.get<DutyPersonPage>(this.dutyPersonsUrl, {params, observe: 'response'})
      .pipe(map(response => {
        return {
          dutyPersons: (response.body || []) as DutyEmployee[],
          total: Number(response.headers.get('x-total-count')) || 0
        };
      }));
  }

  deleteOnDutyPerson(dutyPersonId: DutyEmployee['id']): Observable<any> {
    return this.httpClient.delete(this.dutyPersonsByIdUrl(dutyPersonId));
  }

  updateOnDutyPerson(payload: UpdateOnDutyData): Observable<any> {
    return this.httpClient.put(this.onDutyPersonsV2Url, payload);
  }

  createOnDutyPerson(payload: CreateOnDutyData): Observable<any> {
    return this.httpClient.post(this.onDutyPersonsV2Url, payload);
  }

  loadAccount(): Observable<IUser> {
    return this.httpClient.get<IUser>(this.accountUrl);
  }

  updateAccount(payload: IUserUpdatePayload, avatar?: File): Observable<IUser> {
    if (avatar) {
      return this.addUserAvatar(avatar).pipe(
        switchMap(() => {
          return this.httpClient.post<IUser>(this.accountUrl, {...payload, profile: {phone: payload.phone}});
        })
      );
    } else {
      return this.httpClient.post<IUser>(this.accountUrl, {...payload, profile: {phone: payload.phone}});
    }
  }

  getAccountAvatar(accountId: IUser['id'], size: ImgSize = ImgSize.Size480X320): Observable<any>  {
    const url: string = `${this.accountAvatarUrl}/image-${size}-${accountId}.jpg`;
    const headers = this.httpHelperService.createRequestHeaders({
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0'
    });
    return this.httpClient.get(url, {  responseType: 'blob' , headers}).pipe(
      catchError(() => of(environment.baseUserAvatarImage)),
      switchMap(response => {
        if (!response) {
          return of(environment.baseUserAvatarImage);
        }
        return typeof response === 'string' ? of(response) : fileToBase64(response);
      }),
    );
  }

  private addUserAvatar(logo: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', logo);
    return this.httpClient.post(this.avatarUrl, formData);
  }
}
