import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LangSelectComponent } from './lang-select.component';

import {NzSelectModule} from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LangSelectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NzSelectModule,
  ],
  exports: [LangSelectComponent],
})
export class LangSelectModule { }
