import {DevicesPage, IDeviceFetchQueryParams} from "./panels.service";
import {RecordModel} from "../../core/models/record.model";
import {IConnectionStatus, ThingDTO, IDeviceMetrics} from "./panels.model";
import { ThingMetric } from '../hierarchy/thing-metric.model';

export namespace DevicesActions {

  export class Fetch {
    static readonly type = '[Devices] Fetch';

    constructor(
      public page: number,
      public params?: IDeviceFetchQueryParams) {
    }
  }

  export class FetchByCustomerId {
    static readonly type = '[Devices] FetchByCustomerId';

    constructor(
      public customerId: number,
      public page: number,
      public params?: IDeviceFetchQueryParams) {
    }
  }

  export class ReassignDeviceByModelId {
    static readonly type = '[Devices] Fetch reassign device for model';

    constructor(
      public newModelId: number,
      public listOfDevicesId:  Array<number>
      ) {
    }
  }

  export class FetchDeviceByModelId {
    static readonly type = '[Devices] Fetch by model id';

    constructor(
      public id: RecordModel['id'],
      public page: number,
      public params?: IDeviceFetchQueryParams) {
    }
  }

  export class FetchedDeviceByModelId {
    static readonly type = '[Devices] Fetched';

    constructor(public devicesPage: DevicesPage) {
    }
  }

  export class DeleteThing {
    static readonly type = '[Devices] delete thing';

    constructor(
      public id: number,
      public params?: IDeviceFetchQueryParams) {}
  }

  export class DeleteSeveralThings {
    static readonly type = '[Devices] delete several things';

    constructor(
      public arrayThingsId: RecordModel['id'][],
      public params?: IDeviceFetchQueryParams) {}
  }

  export class Fetched {
    static readonly type = '[Devices] Fetched';

    constructor(public devicesPage: DevicesPage) {
    }
  }

  export class PageSize {
    static readonly type = '[Devices] Page size';

    constructor(
      public size: number,
      public params?: IDeviceFetchQueryParams) {
    }
  }

  export class FetchDeviceInfo {
    static readonly type = '[Devices] Device info fetch';

    constructor(public id: number) {
    }
  }

  export class FetchDeviceInfoById {
    static readonly type = '[Devices] FetchDeviceInfoById';

    constructor(public id: ThingDTO['id']) {
    }
  }

  export class DeviceInfoFetched {
    static readonly type = '[Devices] Device info fetched';

    constructor(public device: ThingDTO) {
    }
  }

  export class DeviceUnassign {
    static readonly type = '[Devices] Device unassign';

    constructor(public serial: String) {
    }
  }

  export class FetchDeviceMetrics {
    static readonly type = '[Devices] Device metrics';

    constructor(public serial: String) {
    }
  }

  export class DeviceMetricsFetched {
    static readonly type = '[Devices] Device metrics fetched';

    constructor(public deviceMetrics: IDeviceMetrics) {
    }
  }

  export class UpdateMetrics {
    static readonly type = '[Devices] Update metrics';

    constructor(public metrics: ThingMetric) {
    }
  }

  export class UpdateConnectionStatus {
    static readonly type = '[Devices] Update connection status';

    constructor(public connectionStatus: IConnectionStatus) {
    }
  }

  export class ChangeActiveMode {
    static readonly type = '[Devices] Change Active Mode';

    constructor(public serial: string,
                public activeModeId: number,
                public temperature?: number) {
    }
  }

  export class ChangeSuspendMode {
    static readonly type = '[Devices] Change Suspend Mode';

    constructor(public deviceIds: ThingDTO['id'][]) {
    }
  }

  export class DeleteSuspendMode {
    static readonly type = '[Devices] Delete Suspend Mode';

    constructor(public deviceIds: ThingDTO['id'][]) {
    }
  }

  export class FetchMapDevicesData {
    static readonly type = '[Devices] Devices map fetch';

    constructor(
      public params?: IDeviceFetchQueryParams) {
    }
  }

  export class CleanDeviceList {
    static readonly type = '[Devices] Clean device list';
  }

  export class FetchThingTypes {
    static readonly type = '[Devices] Fetch thing types';
  }

}
