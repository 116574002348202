import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '../../environments/environment';
import { AuthState, AuthStateModel } from './auth/auth.state';
import { OverlayModule } from '@angular/cdk/overlay';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { DevicesState, DevicesStateModel } from "./panel/panels.state";
import { CompanyState, CompanyStateModel } from "./company/company.state";
import { UserState, UserStateModel } from "./user/user.state";
import { FirmwareState } from './firmware/firmware.state';

export const GLOBAL_STATES = [AuthState, CompanyState, UserState, FirmwareState, DevicesState];

export interface AppState {
  auth: AuthStateModel;
  device: DevicesStateModel;
  company: CompanyStateModel;
  user: UserStateModel;
}

@NgModule({
  imports: [
    OverlayModule,
    NgxsModule.forRoot(GLOBAL_STATES, { developmentMode: !environment.production }),
    NgxsResetPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
      name: 'Iot Hub',
    }),
  ],
  providers: [NzNotificationService]
})
export class StateModule {
}
