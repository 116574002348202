import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {Observable, of} from 'rxjs';
import {filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {UserRole} from '../../store/user/user.model';
import {AuthState} from '../../store/auth/auth.state';
import {UserModel} from '../models/user.model';
import {environment} from '../../../environments/environment';


export const GPO_ROLES: UserRole[] = [
  UserRole.GPOAdmin,
  UserRole.GPOManager,
  UserRole.GPOUser,
  UserRole.GPOEngineer,
];

export const NOT_IOT_ROLES: UserRole[] = [
  UserRole.CompanyAdmin,
  UserRole.CompanyManager,
  UserRole.CompanyUser,
  UserRole.CompanyEngineer,
];

export const NO_USER_ROLES: UserRole[] = [
  UserRole.GPOAdmin,
  UserRole.GPOManager,
  UserRole.GPOEngineer,
];

export const NO_COMPANY_USER_ROLES: UserRole[] = [
  UserRole.CompanyAdmin,
  UserRole.CompanyManager,
  UserRole.CompanyEngineer,
];

export const NO_USER_ROLE: UserRole[] = [
  ...NO_USER_ROLES,
  ...NO_COMPANY_USER_ROLES,
];

@Injectable({
  providedIn: 'root',
})
export class PermissionService {

  constructor(
    private store: Store,
  ) {
  }

  public isIotUser$(): Observable<boolean> {
    return of(this.isIotUser());
  }

  public isIotUser(): boolean {
    return this.hasRole([...GPO_ROLES]);
  }

  public isIotAdminRole(): boolean {
    return this.hasRole([UserRole.GPOAdmin]);
  }

  public isIotUserRole(): boolean {
    return this.hasRole([UserRole.GPOUser]) &&
      !this.hasRole([UserRole.GPOAdmin]) &&
      !this.hasRole([UserRole.GPOManager]) &&
      !this.hasRole([UserRole.GPOEngineer]);
  }


  public hasRole(roles: UserRole[]): boolean {
    const profile: UserModel = this.store.selectSnapshot(AuthState.profile);
    if (!profile) {
      return false;
    }

    for (const role of roles) {
      if (profile.authorities.indexOf(role) !== -1) {
        return true;
      }
    }

    return false;
  }

  public hasOnlyOneRole(roles: UserRole[]): boolean {
    return this.hasRole(roles) && this.getUserRoles().length === 1 && !this.hasRole([UserRole.GPOAdmin, UserRole.CompanyAdmin]);
  }

  public getUserRoles(): UserRole[] {
    const profile: UserModel = this.store.selectSnapshot(AuthState.profile);
    if (!profile) {
      return [];
    }
    return profile.authorities;
  }

}
