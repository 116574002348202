import {
  CreateOnDutyData,
  DutyEmployee,
  DutyPerson,
  IPerson,
  IUser,
  IUserUpdatePayload,
  UpdateOnDutyData,
  UsersInfo
} from './user.model';
import {DutyPersonPage} from './user.service';
import {RecordModel} from '../../core/models/record.model';

export namespace UserActions {

  export class InfoFetched {
    static readonly type = '[User] info fetched';

    constructor(public info: UsersInfo) {
    }
  }

  export class OnDutyPersonFetch {
    static readonly type = '[User] fetch duty person';

    constructor() {
    }
  }

  export class OnDutyPersonFetched {
    static readonly type = '[User] duty person fetched';

    constructor(public dutyPerson: DutyPerson) {
    }
  }

  export class DeleteOnDutyPerson {
    static readonly type = '[User] duty person delete';

    constructor(public dutyPersonId: DutyEmployee['id']) {
    }
  }

  export class DeleteOnDutyPersonDeleted {
    static readonly type = '[User] duty person deleted';

    constructor(public dutyPersonId: DutyEmployee['id']) {
    }
  }

  export class UpdateOnDutyPerson {
    static readonly type = '[User] duty person update';

    constructor(public payload: UpdateOnDutyData) {
    }
  }

  export class OnDutyPersonUpdated {
    static readonly type = '[User] duty person updated';

    constructor(public payload: UpdateOnDutyData) {
    }
  }

  export class CreateOnDutyPerson {
    static readonly type = '[User] duty person create';

    constructor(public payload: CreateOnDutyData) {
    }
  }

  export class OnDutyPersonCreated {
    static readonly type = '[User] duty person created';

    constructor(public payload: DutyEmployee) {
    }
  }

  export class EmployeesFetch {
    static readonly type = '[User] fetch employee';

    constructor(
      public name: string = '',
      public userId?: RecordModel['id'],
      public employeeForReport?: boolean,
      public companyId?: RecordModel['id']) {
    }
  }

  export class EmployeesFetched {
    static readonly type = '[User] employee fetched';

    constructor(public employees: IPerson[]) {
    }
  }

  export class FetchPersons {
    static readonly type = '[User] fetch persons';

    constructor(public page: number, public name: string = '') {
    }
  }

  export class PersonsFetched {
    static readonly type = '[User] duty persons fetched';

    constructor(public page: DutyPersonPage) {
    }
  }

  export class LoadAccount {
    static readonly type = '[User] LoadAccount';

    constructor() {
    }
  }

  export class AccountLoaded {
    static readonly type = '[User] AccountLoaded';

    constructor(public account: IUser) {
    }
  }

  export class UpdateAccount {
    static readonly type = '[User] UpdateAccount';

    constructor(public payload: IUserUpdatePayload, public avatar?: File) {
    }
  }

  export class AccountUpdate {
    static readonly type = '[User] AccountUpdate';

    constructor(public account: IUser) {
    }
  }

}

