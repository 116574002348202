import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from "./core/guards/auth.guard";
import {LoggedGuard} from "./core/guards/logged.guard";
import {NotFoundComponent} from "./components/miscellaneous/not-found/not-found.component";


const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'quick-controller',
    loadChildren: () => import('./pages/quick-controller/quick-controller.module')
      .then(m => m.QuickControllerModule),
  },
  {
    path: 'create-company',
    loadChildren: () => import('./pages/companies/public-company-create/public-company-create.module')
      .then(m => m.PublicCompanyCreateModule),
  },
  {
    path: 'server-unreachable',
    loadChildren: () => import('./pages/server-unreachable/server-unreachable.module').then(m => m.ServerUnreachableModule),
  },
  {
    path: 'auth/activate-account',
    loadChildren: () => import('./auth/account-activate/account-activate.module').then(m => m.AccountActivateModule),
  },
  {
    path: 'enduser/activate-account',
    loadChildren: () => import('./auth/completion-registration-enduser/end-user-activate-account.module')
      .then(m => m.EndUserActivateAccount),
  },
  {
    path: 'enduser/forgot-password-account',
    loadChildren: () => import('./auth/enduser-forgot-pass/enduser-forgot-pass.module')
      .then(m => m.EnduserForgotPassModule)
  },
  {path: '', redirectTo: 'pages/dashboard', pathMatch: 'full'},

  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
