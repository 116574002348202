import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageHelper {
  setItem<T>(items: { [key: string]: T }, rememberMe: boolean): void {
    const storage = rememberMe ? localStorage : sessionStorage;

    Object.keys(items).forEach(key => {
      const stringItem = JSON.stringify(items[key]);
      storage.setItem(key, stringItem);
    });
  }

  getItems(keys: string[], rememberMe: boolean): { [key: string]: any } {
    const storage = rememberMe ? localStorage : sessionStorage;
    const storedItems: { [key: string]: any } = {};

    keys.forEach(key => {
      const storedItem = storage.getItem(key);
      if (storedItem) {
        storedItems[key] = JSON.parse(storedItem);
      }
    });
    return storedItems;
  }
}


