import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '../../store/auth/auth.state';
import { RecordModel } from '../models/record.model';
import { CompanyState } from "../../store/company/company.state";
import { catchError } from "rxjs/operators";
import { SentryErrorHandler } from '@sentry/angular';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private store: Store) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('api/')) {
      return next.handle(request);
    }

    const token = this.store.selectSnapshot<string>(AuthState.token);
    const companyId = this.store.selectSnapshot<RecordModel['id']>(CompanyState.companyId);

    if (!!token) {
      const headers: any = {
        Authorization: `Bearer ${token}`,
      };
      if (companyId && !request.headers.has('ActiveCompany')) {
        headers.ActiveCompany = companyId.toString();
      }

      request = request.clone({
        setHeaders: headers
      });
    }

    return next.handle(request).pipe(catchError(err => {
      new SentryErrorHandler(err);
      return throwError(err);
    }));
  }
}
