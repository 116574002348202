import {Component, OnDestroy, OnInit} from '@angular/core';
import {Actions, ofActionCompleted, Store} from "@ngxs/store";
import {AuthActions} from "./store/auth/auth.action";
import {debounceTime, filter, map, takeUntil} from "rxjs/operators";
import {GLOBAL_STATES} from "./store/state.module";
import {merge, Subject} from "rxjs";
import {StateReset} from "ngxs-reset-plugin";
import {ServerHealthyService} from "./core/services/server-healthy.service";
import {AuthState} from "./store/auth/auth.state";
import {CompanyState} from "./store/company/company.state";
import {Destroyer} from "./core/helpers/destroyer.helpers";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends Destroyer implements OnInit {
  isCollapsed = false;

  constructor(
    private actions$: Actions,
    private store: Store,
    private serverHealthyService: ServerHealthyService
  ) {
    super()
  }

  ngOnInit(): void {
    this.listenForCompanyChannel();


    this.actions$.pipe(
      ofActionCompleted(AuthActions.Logout),
      takeUntil(this.destroy$),
    )
      .subscribe(() => {
        this.store.dispatch(new StateReset(...GLOBAL_STATES));
        this.serverHealthyService.resetHealthyEmitter();
      });
  }


  public listenForCompanyChannel(): void {
    const loggedIn$ = this.store.select(AuthState.loggedIn)
      .pipe(
        filter(isLoggedIn => isLoggedIn),
        map(() => this.store.selectSnapshot(CompanyState.companyId)),
      );
    const activeCompanyId$ = this.store.select(CompanyState.companyId)
      .pipe(filter(() => this.store.selectSnapshot(AuthState.loggedIn)));

    merge(loggedIn$, activeCompanyId$)
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
