import { RecordModel } from "../../core/models/record.model";
import { DeviceStatus } from "../../core/const/types";
import { ILocation } from "../../core/models/map.model";
import { ICompany } from "../company/company.model";

import { ThingType } from '../firmware/firmware.model';
import { ThingMetric, WifiStatus } from '../hierarchy/thing-metric.model';
import { ExternalSensor } from '../hierarchy/hierarchy.model';

export interface IDeviceGroup extends RecordModel {
  name: string;
  description: string;
  addedOn: Date;
  modifiedOn: Date;
  state: any;
}

export interface DeviceModel {
  id: number;
  serial?: string;
  disabled?: boolean;
  checked: boolean;
  expand: boolean;
  thing: ThingDTO;
  mostRecentConnectionThrough: any;
  photos: any[];
}

export interface IDeviceInfo extends RecordModel {
  serial: string;
}

export interface DevicesInfo {
  online: number;
  offline: number;
  total: number;
}

export interface ThingTypeInfo {
  color: string;
  label: string;
}

export interface ThingDTO {
  id: number;
  name: string;
  addedOn: string;
  company: ICompany;
  firstConnectionTime: string;
  mostRecentConnectionTime?: any;
  online?: boolean;
  lastHttpConnectionTime?: Date;
  connectionStatus?: ThingConnectionStatus;
  metrics?: ThingMetric | null;
  upstreamConfiguration?: UpstreamConfiguration;
  downstreamConfiguration?: DownStreamConfiguration;
  externalSensor?: ExternalSensor;
  owner?: IThingOwner;
  thingModel?: {
    id: number;
    name: string;
  };
  serial: string;
  status?: DeviceStatus;
  location?: {
    country: string;
    city: string;
  }
  thingType: ThingType;
}
export const DEFAULT_FETCH_QUERY_PARAMS = {
  sort: ['addedOn,desc'],
};

export interface UpstreamConfiguration {
  configuration: IUpstreamConfiguration;
  modifiedOn: Date;
}

export interface DownStreamConfiguration {
  addedOn: Date;
  configuration: IDownstreamConfiguration;
}

export interface LimitsDTO {
  maxDefinedTemp: number;
  maxSurfaceTemp: number;
  version: number;
}

export interface IConnectionStatus {
  online: boolean;
  connectionStatus: ThingConnectionStatus;
}

export enum ThingConnectionStatus {
  DISCONNECTED = "DISCONNECTED",
  CONNECTED_HTTP = "CONNECTED_HTTP",
  CONNECTED_MQTT = "CONNECTED_MQTT"
}

export interface  IUpstreamConfiguration{
  static?: IStatic;
  thingConfig?: IThingConfig;
}

export interface IThingConfig {
  coreId?: string;
  model?: number;
  type?: number;
  version?: number;
}

export interface IDownstreamConfiguration {
  firmware?: IFirmware;
  timer?: TimerDTO;
  limits: LimitsDTO;
  state?: StateDTO;
}



export interface IFirmware {
  desiredVer?: string;
  forceCounter?: number;
  version?: number;
}

export interface IStatic {
  activeId: number;
  mode: IThingMode;
  state: IThingState;
}

export enum FinalActionType {
  state = 'STATE',
  manual = 'MANUAL',
  custom = 'CUSTOM',
}

export interface TimerDTO {
  active: boolean;
  duration: number;
  startTime: number;
  finalAction: StateDTO | ManualModeDTO | CustomModeDTO;
}

interface StateDTO {
  type: FinalActionType.state;
  state_on: boolean;
}

interface ManualModeDTO {
  type: FinalActionType.manual;
  manualMode: ManualModeTimerDTO;
}

interface ManualModeTimerDTO {
  manualModeT: number;
  maxSurfT: number;
}

interface CustomModeDTO {
  type: FinalActionType.custom;
  mode: ModeDTO;
}


export interface IThingState {
  state?: boolean;
  state_on: boolean;
}

export interface IThingMode {
  activeId?: number;
  manualModeTemperature?: number;
  mode?: ModeDTO[];
}

export interface ModeDTO {
  emoji?: number;
  id?: number;
  name?: {
    [key: string]: string;
  };
  type?: string;
  schedules?: IDaySchedule[];
}

export interface IDaySchedule {
  days: number;
  intervals: IInterval[];
}

export interface IInterval {
  t: number
  to: number
  from: number
}

export enum ModeType {
  MANUAL = 'MANUAL',
  CUSTOM = 'CUSTOM',
  UNDEFINED = 'UNDEFINED'
}

export interface IThingOwner {
  id: number;
  activated: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  login: string;

}

export interface IDeviceCoordinates {
  id: RecordModel['id'];
  location: ILocation;
  serial?: string;
  status?: DeviceStatus;
}

export interface ThingItem {
  id: RecordModel['id'];
  serial: string;
}

export interface IDeviceMetrics {
  metrics?: ThingMetric | null;
  timestamp: Date;
}


export interface IThingLocationData {
  lat: number,
  lon: number,
  things: IThingLocationItem[]
}

export interface IThingLocationItem {
  id: number;
  serial: string;
  online: boolean;
}

export enum HeaterState {
  ON = "ON",
  OFF = "OFF",
}

export enum TriacState {
  ON = "ON",
  OFF = "OFF",
}



export interface Sensors {
  temperature?: TemperatureSensors | null;
  electric?: ElectricSensors | null;
}

export interface TemperatureSensors {
  temperatureOuter?: number | null;
  temperatureInner?: number | null;
}

export interface ElectricSensors {
  voltage?: number | null;
  current?: number | null;
  power?: number | null;
  consumption?: number | null;
}

export interface Wifi {
  status?: WifiStatus | null;
  ssid?: string | null;
}

export interface Ble {
  status?: BleStatus | null;
}

export interface HotSpot {
  status?: HotSpotStatus | null;
}

export enum BleStatus {
  IDLE = "IDLE",
  ON = "ON",
  CONNECTED = "CONNECTED",
}

export enum HotSpotStatus {
  IDLE = "IDLE",
  ON = "ON",
  CONNECTED = "CONNECTED",
}

export enum MetricsAlert {
  SENS_OUT_BROKEN = "sen_out_br",
  SENS_IN_BROKEN = "sen_in_br",
  OVERVOLTAGE = "vo_ov",
  OVERCURRENT = "cu_ov",
  UNDERVOLTAGE = "vo_un",
}



export const NUMBER_OF_PANELS_FOR_MAP = 10000
