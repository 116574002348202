import {LoadableStateModel} from 'src/app/core/models/loadable.model';
import {Injectable, NgZone} from "@angular/core";
import {
  DEFAULT_FETCH_QUERY_PARAMS,
  DeviceModel,
  DevicesInfo,
  ThingDTO,
  IDeviceCoordinates,
  IThingLocationData,
  IDeviceMetrics
} from "./panels.model";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {StoreState} from "../store.state";
import {DevicesService, IDeviceFetchQueryParams, IDeviceHistory} from "./panels.service";
import {PageableStateModel} from "../../core/models/pageable.model";
import {RecordModel} from "../../core/models/record.model";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {TranslateService} from "@ngx-translate/core";
import {ServerHealthyService} from "../../core/services/server-healthy.service";
import {Router} from "@angular/router";
import {ErrorMessageService} from "../../core/services/error-message.service";
import {Pagination} from "../../core/models/request";
import {DevicesActions} from "./panels.action";
import {first} from "rxjs/operators";
import {cloneDeep} from "lodash";


export interface DevicesStateModel extends LoadableStateModel, PageableStateModel {
  devices: DeviceModel[];
  devicesLocations: IDeviceCoordinates[];
  deviceHistory: { data: IDeviceHistory[]; } & LoadableStateModel & PageableStateModel;
  fetchQueryParams: IDeviceFetchQueryParams;
  defaultFetchQueryParams: IDeviceFetchQueryParams;
  info?: DevicesInfo;
  selectedDeviceId?: RecordModel['id'];
  device?: ThingDTO;
  deviceMetrics?: IDeviceMetrics;
  devicesLocationData: IThingLocationData[]
  thingsTypes: string[]
}


@State<DevicesStateModel>({
  name: 'devices',
  defaults: {
    devices: [],
    devicesLocations: [],
    total: 0,
    info: null,
    page: 1,
    size: 25,
    selectedDeviceId: null,
    devicesLocationData: [],
    thingsTypes: [],
    deviceHistory: {
      data: [],
      page: 1,
      size: 10,
      total: 0,
      loading: true,
    },
    fetchQueryParams: DEFAULT_FETCH_QUERY_PARAMS,
    defaultFetchQueryParams: DEFAULT_FETCH_QUERY_PARAMS,
    loading: true,
  }
})

@Injectable({providedIn: 'root'})
export class DevicesState extends StoreState<DevicesStateModel> {

  constructor(
    private devicesService: DevicesService,
    protected notificationService: NzNotificationService,
    protected translateService: TranslateService,
    protected serverHealthyService: ServerHealthyService,
    private ngZone: NgZone,
    private router: Router,
    protected errorMessageService: ErrorMessageService,
  ) {
    super(notificationService, translateService, serverHealthyService, errorMessageService);
  }

  @Selector()
  static loading(state: LoadableStateModel) {
    return state.loading;
  }

  @Selector()
  static getDevices(state: DevicesStateModel) {
    return state.devices;
  }

  @Selector()
  static total(state: DevicesStateModel) {
    return state.total;
  }

  @Selector()
  static pagination(state: DevicesStateModel) {
    return {page: state.page, size: state.size} as Pagination;
  }

  @Selector()
  static info(state: DevicesStateModel) {
    return state.info;
  }

  @Selector()
  static device(state: DevicesStateModel) {
    return state.device;
  }

  @Selector()
  static thingsTypes(state: DevicesStateModel) {
    return state.thingsTypes;
  }

  @Selector()
  static timer(state: DevicesStateModel) {
    return state.device.downstreamConfiguration.configuration.timer;
  }

  @Selector()
  static metrics(state: DevicesStateModel) {
    return state.device.metrics;
  }

  @Selector()
  static deviceMetrics(state: DevicesStateModel) {
    return state.deviceMetrics;
  }

  @Selector()
  static onlineStatus(state: DevicesStateModel) {
    return state.device.online;
  }

  @Selector()
  static connectionStatus(state: DevicesStateModel) {
    return state.device.connectionStatus;
  }

  @Selector()
  static configuration(state: DevicesStateModel) {
    return state.device.upstreamConfiguration.configuration;
  }

  @Selector()
  static deviceSerial(state: DevicesStateModel) {
    return state.device?.serial;
  }

  @Selector()
  static fetchQueryParams(state: DevicesStateModel) {
    return state.fetchQueryParams;
  }

  @Selector()
  static devicesLocationData(state: DevicesStateModel) {
    return state.devicesLocationData;
  }

  @Action(DevicesActions.Fetch)
  fetch(ctx: StateContext<DevicesStateModel>, {page, params}: DevicesActions.Fetch) {
    const {size, defaultFetchQueryParams} = ctx.getState();
    ctx.patchState({
      loading: true,
      defaultFetchQueryParams: {...defaultFetchQueryParams}
    });
    this.devicesService.fetch({page, size}, { ...params})
      .pipe(
        first(),
      )
      .subscribe(
        response => {
          const {fetchQueryParams} = ctx.getState();
          ctx.patchState({fetchQueryParams: {sort: fetchQueryParams.sort, ...params}, page: page});
          ctx.dispatch(new DevicesActions.Fetched({devices: response.devices, total: response.total}));
        },
        (e) => this.errorHandler(e, ctx),
      );
  }

  @Action(DevicesActions.FetchByCustomerId)
  fetchByCustomerId(ctx: StateContext<DevicesStateModel>, {customerId, page, params}: DevicesActions.FetchByCustomerId) {
    const {size, defaultFetchQueryParams} = ctx.getState();
    ctx.patchState({
      loading: true,
      defaultFetchQueryParams: {...defaultFetchQueryParams}
    });
    this.devicesService.fetchByCustomerId(customerId, {page, size}, params)
      .pipe(
        first(),
      )
      .subscribe(
        response => {
          ctx.dispatch(new DevicesActions.Fetched({devices: response.devices, total: response.total}));
        },
        (e) => this.errorHandler(e, ctx),
      );
  }

  @Action(DevicesActions.FetchDeviceByModelId)
  fetchByModelId(ctx: StateContext<DevicesStateModel>, {id, page, params}: DevicesActions.FetchDeviceByModelId) {
    const {size, defaultFetchQueryParams} = ctx.getState();
    ctx.patchState({
      loading: true,
      defaultFetchQueryParams: {...defaultFetchQueryParams}
    });
    this.devicesService.fetchByModelId(id)
      .pipe(
        first(),
      )
      .subscribe(
        response => {
          const {fetchQueryParams} = ctx.getState();
          ctx.patchState({fetchQueryParams: {sort: fetchQueryParams.sort, ...params}});
          ctx.dispatch(new DevicesActions.Fetched({devices: response.devices, total: response.total}));
        },
        (e) => this.errorHandler(e, ctx),
      );
  }


  @Action(DevicesActions.DeleteThing)
  private deleteThing(ctx: StateContext<DevicesStateModel>, { id, params }: DevicesActions.DeleteThing) {
    ctx.patchState({
      loading: true
    })
    this.devicesService.deleteThing(id)
      .subscribe(() => {
        ctx.dispatch(new DevicesActions.Fetch(ctx.getState().page, params))
        },
        (e) => this.errorHandler(e, ctx));
  }

  @Action(DevicesActions.DeleteSeveralThings)
  private deleteSeveralThing(ctx: StateContext<DevicesStateModel>, {
    arrayThingsId, params
  }: DevicesActions.DeleteSeveralThings) {
    ctx.patchState({"loading": true})

    this.devicesService.deleteSeveralThing(arrayThingsId)
      .subscribe(() => {
            ctx.dispatch(new DevicesActions.Fetch(ctx.getState().page, params))
          },
          (e) => this.errorHandler(e, ctx));
  }


  @Action(DevicesActions.PageSize)
  pageSize(ctx: StateContext<DevicesStateModel>, {size}: DevicesActions.PageSize) {
    const {fetchQueryParams} = ctx.getState();
    ctx.patchState({size, page: 1, loading: true});
    this.devicesService.fetch({page: 1, size}, fetchQueryParams)
      .pipe(first())
      .subscribe(
        response => ctx.dispatch(new DevicesActions.Fetched(response)),
        (e) => this.errorHandler(e, ctx),
      );
  }

  @Action(DevicesActions.Fetched)
  fetched({patchState}: StateContext<DevicesStateModel>, {devicesPage}: DevicesActions.Fetched) {
    patchState({...devicesPage, loading: false});
  }

  @Action(DevicesActions.FetchDeviceInfo)
  private fetchDevice(ctx: StateContext<DevicesStateModel>, {id}: DevicesActions.FetchDeviceInfo) {
    ctx.patchState({
      loading: true, deviceHistory: {
        data: [],
        page: 1,
        size: 10,
        total: 0,
        loading: true,
      }
    });

    this.devicesService.fetchDevice(id)
      .subscribe(
        (response) => ctx.dispatch(new DevicesActions.DeviceInfoFetched(response)),
        (e) => {
          this.errorHandler(e, ctx);
          this.router.navigate(['pages', 'devices']);
        });
  }

  @Action(DevicesActions.DeviceInfoFetched)
  private deviceFetched({patchState}: StateContext<DevicesStateModel>, {device}: DevicesActions.DeviceInfoFetched) {
    patchState({device, loading: false});
  }

  @Action(DevicesActions.DeviceUnassign)
  private unassignDevice(ctx: StateContext<DevicesStateModel>, { serial }: DevicesActions.DeviceUnassign) {
    const {device} = ctx.getState();
    ctx.patchState({loading: true});
    return this.devicesService.unassign(serial).subscribe(
      () => {
        ctx.patchState({device: {...device, owner: null}, loading: false});
      },
      (e) => {
        this.errorHandler(e, ctx);
        ctx.patchState({loading: false});
      }
    )
  }

  @Action(DevicesActions.ChangeActiveMode)
  private changeActiveMode(ctx: StateContext<DevicesStateModel>, { serial, activeModeId, temperature }: DevicesActions.ChangeActiveMode) {
    const {device} = ctx.getState();
    return this.devicesService.changeActiveMode(serial, activeModeId, temperature).subscribe(
      () => {
        const updatedDevice = cloneDeep( {...device});
        updatedDevice.upstreamConfiguration.configuration.static.mode.activeId = activeModeId;
        ctx.patchState({device: updatedDevice});
      },
      (e) => {
        this.errorHandler(e, ctx);
      }
    )
  }

  @Action(DevicesActions.UpdateConnectionStatus)
  private updateOnlineStatus(ctx: StateContext<DevicesStateModel>, { connectionStatus }: DevicesActions.UpdateConnectionStatus) {
    ctx.patchState({
      device: {
        ...ctx.getState().device,
        online: connectionStatus.online,
        connectionStatus: connectionStatus.connectionStatus
      }
    });
  }

  @Action(DevicesActions.FetchDeviceMetrics)
  private fetchDeviceMetrics(ctx: StateContext<DevicesStateModel>, { serial }: DevicesActions.FetchDeviceMetrics) {
    ctx.patchState({loading: true});
    return this.devicesService.fetchDeviceMetrics(serial).subscribe(
      (response) => ctx.dispatch(new DevicesActions.DeviceMetricsFetched(response)),
      (e) => {
        this.errorHandler(e, ctx);
        ctx.patchState({loading: false});
      }
    )
  }

  @Action(DevicesActions.DeviceMetricsFetched)
  private deviceMetricsFetched({patchState}: StateContext<DevicesStateModel>, {deviceMetrics}: DevicesActions.DeviceMetricsFetched) {
    patchState({deviceMetrics, loading: false});
  }

  @Action(DevicesActions.UpdateMetrics)
  private updateMetrics(ctx: StateContext<DevicesStateModel>, { metrics }: DevicesActions.UpdateMetrics) {
    const {device} = ctx.getState();
    const updatedDevice = cloneDeep( {...device});
    updatedDevice.metrics = metrics;
    ctx.patchState({device: updatedDevice});
  }


  @Action(DevicesActions.ReassignDeviceByModelId)
  private reassignThings(ctx: StateContext<DevicesStateModel>, {
    newModelId,
    listOfDevicesId
  }: DevicesActions.ReassignDeviceByModelId) {
    ctx.patchState({loading: true});
    this.devicesService.reassignThings(newModelId, listOfDevicesId).subscribe(
      () => {
        this.router.url.includes(listOfDevicesId[0].toString())
          ? ctx.dispatch(new DevicesActions.FetchDeviceInfo(listOfDevicesId[0]))
          : ctx.dispatch(new DevicesActions.Fetch(1, {}));
      },
      (e) => {
        this.errorHandler(e, ctx);
      }
    )
  }

  @Action(DevicesActions.ChangeSuspendMode)
  private changeSuspendMode(ctx: StateContext<DevicesStateModel>, { deviceIds }: DevicesActions.ChangeSuspendMode) {
    const {devices} = ctx.getState();
    return this.devicesService.changeSuspendMode(deviceIds).subscribe(
      (data) => {
        const updatedDevices = devices.map(device => {
          const foundDevices = data.find(dataItem => dataItem.id === device.id);
          return foundDevices ? { ...device, ...foundDevices } : device;
        });
        ctx.patchState({ devices: updatedDevices });
      },
      (e) => {
        this.errorHandler(e, ctx);
      }
    )
  }

  @Action(DevicesActions.DeleteSuspendMode)
  private deleteSuspendMode(ctx: StateContext<DevicesStateModel>, { deviceIds }: DevicesActions.DeleteSuspendMode) {
    const {devices} = ctx.getState();
    return this.devicesService.deleteSuspendMode(deviceIds).subscribe(
      (data) => {
        const updatedDevices = devices.map(device => {
          const foundDevices = data.find(dataItem => dataItem.id === device.id);
          return foundDevices ? { ...device, ...foundDevices } : device;
        });
        ctx.patchState({ devices: updatedDevices });
      },
      (e) => {
        this.errorHandler(e, ctx);
      }
    )
  }

  @Action(DevicesActions.CleanDeviceList)
  private cleanDeviceList(ctx: StateContext<DevicesStateModel>) {
    ctx.patchState({devices: []});
  }

  @Action(DevicesActions.FetchMapDevicesData)
  private fetchMapDevicesData(ctx: StateContext<DevicesStateModel>, {params}: DevicesActions.FetchMapDevicesData) {
    ctx.patchState({loading: true});
    return this.devicesService.fetchMapDevicesData(params).subscribe(
      (devicesLocationData: IThingLocationData[]) => ctx.patchState({devicesLocationData, loading: false}),
      (e) => {
        this.notificationService.error(this.translateService.instant('map.error-location-devices'), '');
        this.errorHandler(e, ctx);
        ctx.patchState({loading: false});
      }
    )
  }

  @Action(DevicesActions.FetchThingTypes)
  private fetchThingTypes(ctx: StateContext<DevicesStateModel>) {
    if (ctx.getState().thingsTypes.length) {
      return;
    }
    return this.devicesService.fetchThingTypes().subscribe(
      (thingsTypes: string[]) => ctx.patchState({thingsTypes}),
      (e) => this.errorHandler(e, ctx)
    )
  }
}
