import {StateContext} from '@ngxs/store';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';
import {interval, Observable, of} from 'rxjs';
import {ServerHealthyService} from '../core/services/server-healthy.service';
import {first} from 'rxjs/operators';
import {ErrorMessageService} from '../core/services/error-message.service';

let isLoggedOut = false;

export abstract class StoreState<T> {


  protected constructor(
    protected notificationService: NzNotificationService,
    protected translateService: TranslateService,
    protected serverHealthyService: ServerHealthyService,
    protected errorMessageService: ErrorMessageService,
  ) {
  }

  protected errorHandler(e: any, ctx?: StateContext<T>): Observable<any> {
    console.error(e);

    if (!this.serverHealthyService.isHealthy || isLoggedOut) {
      if (ctx) {
        ctx.patchState({loading: false} as unknown as T);
      }
      return of(e);
    }

    if (e.status === 401 && !e.url.includes('api/authenticate')) {
      isLoggedOut = true;
      interval(3000)
        .pipe(first())
        .subscribe(() => isLoggedOut = false);
    }

    if (e.status === 413) {
      this.notificationService.error(
        this.translateService.instant('messages.request-entity-too-large'),
        '',
        {nzClass: 'notification_error'},
      );
      return of(e);
    }

    if (e.status === 0 || e.status === 504) {
      this.serverHealthyService.checkForHealthy();
      this.notificationService.error(
        this.translateService.instant('messages.server-unreachable'),
        '',
        {nzClass: 'notification_error'},
      );
      if (ctx) {
        ctx.patchState({loading: false} as unknown as T);
      }
      return of(e);
    }

    this.errorMessageService.showErrorMessage(e)

    if (ctx) {
      ctx.patchState({loading: false} as unknown as T);
    }

    return of(e);
  }
}
