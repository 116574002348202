import {RecordModel} from '../../core/models/record.model';
import {ICompany} from "../company/company.model";


export interface UsersInfo {
  activated: number;
  total: number;
}

export interface IPerson extends RecordModel {
  firstName: string;
  lastName: string;
}

export interface IEmployee extends IPerson {
  employeeId?: RecordModel['id'];
  userId?: RecordModel['id'];
  isIot?: boolean;
}

export interface DutyPerson {
  address: string;
  userId: RecordModel['id'];
  avatar: RecordModel['id'];
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  position: string;
  from: Date;
  until: Date;
}

export interface IUserProfile extends RecordModel {
  address: string;
  avatar: any;
  birthday: Date;
  notes: string;
  onLeave: boolean;
  phone: string;
}

export interface IUser extends RecordModel {
  activated?: boolean;
  email?: string;
  firstName?: string;
  imageUrl?: string;
  langKey?: string;
  lastName?: string;
  login: string;
  profile?: IUserProfile;
  resetDate?: any;
  resetKey?: any;
  authorities?: UserRole[];
}

export interface IUserUpdatePayload {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  login: string;
}

export interface Employee extends RecordModel {
  user: IUser;
  position: string;
  startedFrom: string;
  company: ICompany;
}

export interface DutyEmployee extends RecordModel {
  employee: Employee;
  from: Date;
  until: Date;
}

export interface UpdateOnDutyData extends RecordModel {
  employeeId: DutyEmployee['id'];
  from: Date;
  until: Date;
}

export interface CreateOnDutyData {
  employeeId: IEmployee['id'];
  from: Date;
  until: Date;
  id?: RecordModel['id'];
}

export enum UserRole {
  GPOAdmin = 'ROLE_ADMIN',
  GPOUser = 'ROLE_USER',
  GPOManager = 'ROLE_MANAGER',
  GPOEngineer = 'ROLE_ENGINEER',
  CompanyAdmin = 'ROLE_COMPANY_ADMIN',
  CompanyUser = 'ROLE_COMPANY_USER',
  CompanyManager = 'ROLE_COMPANY_MANAGER',
  CompanyEngineer = 'ROLE_COMPANY_ENGINEER',
  // Front-end only
  CompanyOnDutyPerson = 'ROLE_COMPANY_ON_DUTY',
  GPOonDutyPerson = 'ROLE_ON_DUTY',
}
