import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { Language } from 'src/app/core/const/types';
import { LanguageService } from 'src/app/core/services/language.service';
import {interval} from 'rxjs';
import {first} from 'rxjs/operators';
import {detectChanges} from '../../core/helpers/common.helper';

@Component({
  selector: 'app-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.scss']
})
export class LangSelectComponent implements OnInit {
  @Input() autofocusDisableFix: boolean = false;

  selectedLang: Language;

  constructor(
    private languageService: LanguageService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.selectedLang = this.languageService.currLang;

    if (this.autofocusDisableFix) {
      interval(1000)
        .pipe(first())
        .subscribe(() => {
          this.autofocusDisableFix = false;
          detectChanges(this.cdr);
        });
    }
  }

  changeLanguage(lang: Language): void {
    this.languageService.changeLanguage(lang);
    this.selectedLang = lang;
  }
}
