import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RequestService} from '../../core/services/request.service';
import {Observable, of, Subject, takeUntil} from 'rxjs';
import {Feature, ICompany, ICompanyPicker, ICompanyPostPutDTO, MapboxOutput} from './company.model';
import {HttpHelperService} from '../../core/services/http.service';
import {Pagination} from '../../core/models/request';
import {ICompanyPage, ICompanyQueryParams, ImgSize} from './company.model';
import {map, switchMap} from 'rxjs/operators';
import {IAddedByOwner, ICreateCompanyCode} from "../../core/models/company.models";
import {environment} from "../../../environments/environment";


@Injectable({
  providedIn: 'root',
})
export class CompanyService extends RequestService {

  private readonly companiesPickerUrl = this.createUrl('api/vendor/v1/companies');
  private readonly companiesUrl = this.createUrl('api/vendor/v1/companies');
  private readonly companiesOwnersUrl = this.createUrl('api/vendor/v1/companies/owners');
  private readonly companiesCreateUrl = this.createUrl('api/vendor/v1/companies');
  private readonly companiesCreatePublicUrl = this.createUrl('api/public/v1/companies/public');
  private readonly companiesGenerateLinkUrl = this.createUrl('api/public/v1/companies/generate/creating-key');
  private readonly companiesUpdateUrl = this.createUrl('api/vendor/v1/companies');
  private readonly companiesRemoveUrl = this.createUrl('api/vendor/v1/companies');
  private readonly companiesGetOneUrl = this.createUrl('api/vendor/v1/companies');
  private readonly companiesPreviewLogoUrl = this.createUrl('api/companies/previews');
  private readonly companiesCreateLogoUrl = this.createUrl('api/photos/logo');
  private readonly companiesCreateLogoPublicUrl = this.createUrl('api/photos/public/logo');
  private readonly companiesDeleteLogoUrl = this.createUrl('api/companies/logo');
  private readonly mapboxUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'


  updateLogoByCompany$ = new Subject<number>();

  constructor(
    private httpClient: HttpClient,
    private httpHelperService: HttpHelperService,
  ) {
    super();
  }

  public getCompaniesPicker(name: string): Observable<ICompanyPicker[]> {
    const params = this.httpHelperService.createRequestParams({q: name});
    return this.httpClient.get<ICompanyPicker[]>(this.companiesPickerUrl, {params});
  }

  public getCompanies(pagination: Pagination, queryParams: ICompanyQueryParams): Observable<ICompanyPage> {
    const url: string = this.companiesUrl;

    pagination.page--;
    const params = this.httpHelperService.createRequestParams({...pagination, ...queryParams});

    return this.httpClient.get<ICompanyPage>(url, {params, observe: 'response'})
      .pipe(
        map(response => {
          return {
            companies: (response.body || []) as ICompany[],
            page: pagination.page + 1,
            total: Number(response.headers.get('x-total-count')) || 0,
          };
        }));
  }

  public getOwners(email: string): Observable<IAddedByOwner[]> {
    const params = this.httpHelperService.createRequestParams({email});
    return this.httpClient.get<IAddedByOwner[]>(this.companiesOwnersUrl, {params});
  }

  public createCompany(company: ICompanyPostPutDTO, logo?: File): Observable<ICompany> {
    const url: string = this.companiesCreateUrl;
    const logoCreateReq$ = logo ? this.addCompanyLogo(logo, false) : of(null);

    return logoCreateReq$
      .pipe(switchMap(response => {
        if (response) {
          company.logoId = response.id;
        }
        return this.httpClient.post<ICompany>(url, company);
      }));
  }

  public createCompanyPublic(company: ICompanyPostPutDTO, code: string, logo?: File): Observable<ICompany> {
    const url: string = this.companiesCreatePublicUrl + `/${code}`;
    const logoCreateReq$ = logo ? this.addCompanyLogo(logo, true) : of(null);

    return logoCreateReq$
      .pipe(switchMap(response => {
        if (response) {
          company.logoId = response.id;
        }
        return this.httpClient.post<ICompany>(url, company);
      }));
  }

  public updateCompany(company: ICompanyPostPutDTO, logo?: File): Observable<ICompany> {
    const url: string = this.companiesUpdateUrl;
    return this.httpClient.put<ICompany>(url, company);
  }

  public removeCompany(companyId: ICompany['id']): Observable<ICompany> {
    const url: string = this.companiesRemoveUrl + `/${companyId}`;
    return this.httpClient.delete<ICompany>(url);
  }

  public updateCompanyAndLogo(company: ICompanyPostPutDTO, logo: any): Observable<any> {
    return this.addCompanyLogo(logo, false)
      .pipe(
        switchMap(response => {
          company.logoId = response.id;
          return this.updateCompany(company);
        })
      );
  }

  public getCompanyLogo(companyId: ICompany['id'], size: ImgSize = ImgSize.Size75X75): Observable<any> {
    const url: string = `${this.companiesPreviewLogoUrl}/image-${size}-${companyId}.jpg`;
    const headers = this.httpHelperService.createRequestHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0'
    });
    return this.httpClient.get(url, {responseType: 'blob', headers});
  }

  public generateLink(): Observable<ICreateCompanyCode> {
    return this.httpClient.get<ICreateCompanyCode>(this.companiesGenerateLinkUrl);
  }

  public getCompanyById(companyId: ICompany['id']): Observable<ICompany> {
    const url: string = this.companiesGetOneUrl + `/${companyId}`;
    return this.httpClient.get<ICompany>(url);
  }

  public removeCompanyLogo(companyId: ICompany['id']): Observable<any> {
    const url: string = this.companiesDeleteLogoUrl + `/${companyId}`;
    return this.httpClient.delete<any>(url);
  }

  private addCompanyLogo(logo: File, isPublicPage: boolean): Observable<any> {
    const url: string = isPublicPage ? this.companiesCreateLogoPublicUrl : this.companiesCreateLogoUrl;
    const formData = new FormData();
    formData.append('file', logo);
    return this.httpClient.post(url, formData);
  }

  public searchAddress(query: string): Observable<any> {
    return this.httpClient.get(this.mapboxUrl + query + '.json?types=address&access_token='
      + environment.mapbox.accessToken)
      .pipe(map(
      (foundAddresses: MapboxOutput) => foundAddresses.features
    ))
  }
}
