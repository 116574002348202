import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import {distinctUntilChanged, first, shareReplay, switchMap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {TOKEN} from '../const/const';

@Injectable({
  providedIn: 'root'
})
export class ServerHealthyService {

  private isHealthyEmitter$ = new BehaviorSubject(true);
  private readonly RECONNECT_DELAY: number = 1500;
  private readonly SHOW_SUCCESS_KEY: string = 'show_server_connection_success';

  constructor(
    private httpClient: HttpClient,
  ) {
    this.showServerReconnectMsg();
  }

  get isHealthy$(): Observable<boolean> {
    return this.isHealthyEmitter$
      .asObservable()
      .pipe(
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1,  refCount: true}),
      );
  }

  get isHealthy(): boolean {
    return this.isHealthyEmitter$.getValue();
  }

  public checkForHealthy(): void {
    if (this.isHealthy) {
      this.serverHealthyRequest$()
        .pipe(first())
        .subscribe(
          success => {},
          error => this.setServerUnhealth(),
        );
    }
  }

  public serverHealthyRequest$(): Observable<any> {
    const token = localStorage.getItem(TOKEN);
    return this.httpClient.get(`${environment.baseUrl}api/server/time`, { headers: { Authorization: `Bearer ${token}`, } });
  }

  public resetHealthyEmitter(): void {
    this.isHealthyEmitter$.next(true);
  }

  private setServerUnhealth(): void {
    this.isHealthyEmitter$.next(false);
    localStorage.setItem(this.SHOW_SUCCESS_KEY, 'true');
    interval(this.RECONNECT_DELAY)
      .pipe(
        switchMap(() => this.serverHealthyRequest$()),
        first(),
      )
      .subscribe(
        success => this.setServerHealth(),
        error => {
          if (error.status !== 401) {
            this.setServerUnhealth();
          }
        },
      );
  }

  private setServerHealth(): void {
    this.isHealthyEmitter$.next(true);
    this.updatePage();
  }

  private updatePage(): void {
    const canUpdatePage = !window.location.href.includes('server-unreachable');
    if (canUpdatePage) {
      window.location.reload();
    }
  }

  private showServerReconnectMsg(): void {
    // TODO SM-1036 resolve circular dependency (https://gpotech.atlassian.net/browse/SM-542)
    // if (localStorage.getItem(this.SHOW_SUCCESS_KEY)) {
    //   localStorage.removeItem(this.SHOW_SUCCESS_KEY);
    //   this.store.select(AuthState.token)
    //     .pipe(
    //       filter(token => !!token),
    //       switchMap(() => this.serverHealthyRequest$()),
    //       first(),
    //     )
    //     .subscribe(() => {
    //       this.nzNotificationService.success(this.translateService.instant('messages.server-reconnection-title'), '');
    //     });
    // }
  }
}
