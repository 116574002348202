import { Injectable } from "@angular/core";
import { RequestService } from "../../core/services/request.service";
import { HttpClient } from "@angular/common/http";
import { HttpHelperService } from "../../core/services/http.service";
import { Store } from "@ngxs/store";
import {
  DeviceModel,
  ThingDTO,
  IDeviceInfo,
  IThingLocationData,
  IDeviceMetrics,
  NUMBER_OF_PANELS_FOR_MAP
} from "./panels.model";
import { DeviceStatus } from "../../core/const/types";
import { RecordModel } from "../../core/models/record.model";
import { Pagination } from "../../core/models/request";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ThingRecipient } from "../../core/classes/Recipient";
import { ShowMode } from "../../pages/things-module/panels-list/things-list.component";

export interface IDeviceHistory {
  id: number;
  deviceId: number;
  serial: string;
  actor: string;
  snapshot: string | ThingDTO;
  device: ThingDTO;
  addedOn: string;
}

export interface DevicesPage {
  devices: DeviceModel[];
  total: number;
}

export interface IDeviceFetchQueryParams {
  serial?: string;
  status?: DeviceStatus;
  groupId?: RecordModel['id'];
  model?: string;
  addedByIds?: number[];
  groupName?: string;
  sort?: string[];
  showMode?: ShowMode;
  thingType?: string;
  page?: number;
}


interface IModeBody {
  recipient: ThingRecipient;
  temperature?: number;
}

@Injectable({
  providedIn: 'root'
})
export class DevicesService extends RequestService {

  constructor(
    private httpClient: HttpClient,
    private httpHelperService: HttpHelperService,
    private store: Store,
  ) {
    super();
  }

  private readonly devicesQueryUrl = `${this.createUrl('api/vendor/v1/heating-panel/filter')}`;
  private readonly severalThingDeleteUrl = `${this.createUrl('api/vendor/v1/company/things')}`;
  private readonly deviceByIdUrl = (deviceId: ThingDTO['id']) => `${this.createUrl('api/vendor/v1/heating-panel/')}${deviceId}`;
  private readonly deleteUrl = (thingId: RecordModel['id']) => `${this.createUrl('api/vendor/v1/company/things')}/${thingId}`;
  private readonly unassignUrl = (serial: String) => `${this.createUrl(`api/gpo/v1/things/${serial}/abandon`)}`;
  private readonly getMetrics = (serial: String) => `${this.createUrl(`api/gpo/v1/things/${serial}/metrics`)}`;
  private readonly thingByModelIdUrl = (modelId: RecordModel['id']) => `${this.createUrl('api/vendor/v1/heating-panel/model')}/${modelId}`;
  private readonly reassignThingsUrl = (modelId: RecordModel['id']) => `${this.createUrl(`api/vendor/v1/model/${modelId}/assign`)}`;
  private readonly devicesByCustomerIdUrl = (customerId: RecordModel['id']) => `${this.createUrl(`api/vendor/v1/customers/${customerId}/things`)}`;
  private readonly changeModeUrl = (modeId: number) => `${this.createUrl(`api/gpo/v1/thing-mode/${modeId}/assign`)}`;
  private readonly changeSuspendURL = `${this.createUrl('api/vendor/v1/heating-panel/suspend')}`;
  private readonly devicesLocationDataUrl = `${this.createUrl('api/vendor/v2/things-per-country')}`;
  private readonly fetchThingTypeUrl = `${this.createUrl('api/vendor/v1/thing-types')}`;


  fetch(pagination: Pagination, fetchQueryParams: IDeviceFetchQueryParams): Observable<DevicesPage> {
    pagination.page--;

    const params = this.httpHelperService.createRequestParams({ ...fetchQueryParams, ...pagination });

    return this.httpClient
      .get<DevicesPage>(this.devicesQueryUrl, {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => {
          return {
            devices: (response.body || []) as DeviceModel[],
            total: Number(response.headers.get('x-total-count')) || 0,
            deviceType: fetchQueryParams
          };
        })
      );
  }

  fetchByCustomerId(customerId: RecordModel['id'], pagination: Pagination, fetchQueryParams: IDeviceFetchQueryParams): Observable<DevicesPage> {
    pagination.page--;

    const params = this.httpHelperService.createRequestParams({ ...fetchQueryParams, ...pagination });

    return this.httpClient
      .get<DevicesPage>(this.devicesByCustomerIdUrl(customerId), {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => {
          return {
            devices: (response.body || []) as DeviceModel[],
            total: Number(response.headers.get('x-total-count')) || 0,
          };
        })
      );
  }

  fetchByModelId(id: RecordModel['id']): Observable<DevicesPage> {
    return this.httpClient
      .get<DevicesPage>(this.thingByModelIdUrl(id), {
        observe: 'response'
      })
      .pipe(
        map(response => {
          return {
            devices: (response.body || []) as DeviceModel[],
            total: Number(response.headers.get('x-total-count')) || 0,
          };
        })
      );
  }

  deleteThing(id: RecordModel['id']): Observable<void> {
    return this.httpClient.delete<void>(this.deleteUrl(id))
  }

  fetchDevice(id: number): Observable<ThingDTO> {
    return this.httpClient.get<ThingDTO>(`${this.deviceByIdUrl(id)}`)
  }

  fetchDeviceInfo(id: number): Observable<IDeviceInfo> {
    return this.httpClient.get<IDeviceInfo>(this.deviceByIdUrl(id));
  }

  fetchDeviceById(id: number): Observable<ThingDTO> {
    return this.httpClient.get<{ thing: ThingDTO }>(this.deviceByIdUrl(id))
      .pipe(
        map((data) => data.thing)
      );
  }

  fetchDeviceMetrics(serial: String): Observable<IDeviceMetrics> {
    return this.httpClient.get<IDeviceMetrics>(this.getMetrics(serial));
  }

  deleteSeveralThing(arrayThingsId: RecordModel['id'][]): Observable<void> {
    return this.httpClient.delete<void>(this.severalThingDeleteUrl, { body: { thingsToDelete: arrayThingsId } })
  }

  reassignThings(newModelId: RecordModel['id'], listOfDevicesId: RecordModel['id'][]): Observable<void> {
    return this.httpClient.put<void>(this.reassignThingsUrl(newModelId), listOfDevicesId);
  }

  unassign(serial: String): Observable<void> {
    return this.httpClient.post<void>(this.unassignUrl(serial), {})
  }

  changeActiveMode(serial: string, activeModeId: number, temperature?: number): Observable<void> {
    let body: IModeBody = {
      recipient: new ThingRecipient(serial),
    };
    if (temperature !== undefined) {
      body.temperature = temperature;
    }
    return this.httpClient.post<void>(this.changeModeUrl(activeModeId), body);
  }

  changeSuspendMode(deviceIds: RecordModel['id'][]): Observable<ThingDTO[]> {
    return this.httpClient.post<ThingDTO[]>(this.changeSuspendURL, deviceIds);
  }

  deleteSuspendMode(deviceIds: ThingDTO['id'][]): Observable<ThingDTO[]> {
    return this.httpClient.delete<ThingDTO[]>(this.changeSuspendURL, { body: deviceIds });
  }

  fetchMapDevicesData(params): Observable<IThingLocationData[]> {
    const fetchQueryParams = this.httpHelperService.createRequestParams({ ...params, ...{sort: ['addedOn,desc'], page: 0, size: NUMBER_OF_PANELS_FOR_MAP } });

    return this.httpClient.get<IThingLocationData[]>(this.devicesLocationDataUrl, {
      params: fetchQueryParams
    });
  }

  fetchThingTypes(): Observable<string[]> {
    return this.httpClient.get<any>(this.fetchThingTypeUrl);
  }
}
