import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {NZ_I18N, ru_RU, uk_UA} from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import uk from '@angular/common/locales/uk';
import ru from '@angular/common/locales/ru';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LangSelectModule} from "./components/lang-select/lang-select.module";
import {AuthExpiredInterceptor} from "./core/interceptors/auth-expired.interceptor";
import {RequestInterceptor} from "./core/interceptors/request.interceptor";
import {LanguageService} from "./core/services/language.service";
import {PlatformService} from "./core/services/platform.services";
import {Store} from "@ngxs/store";
import {Router, RouteReuseStrategy} from "@angular/router";
import {CustomRouteReuseStrategy} from "./core/custom-route-reuse-strategy";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthActions} from "./store/auth/auth.action";
import {StateModule} from "./store/state.module";
import {MiscellaneousModule} from "./components/miscellaneous/miscellaneous.module";
import {CodemirrorModule} from "@ctrl/ngx-codemirror";
import * as Sentry from "@sentry/angular";
import {AppRoutingModule} from "./app-routing.module";

registerLocaleData(en);
registerLocaleData(uk);
registerLocaleData(ru);

function authenticate(store: Store, languageService: LanguageService, platformService: PlatformService) {
  return () => {
    platformService.setOS();
    return languageService.initLanguage()
      .then(() => store.dispatch(new AuthActions.Authenticate()).toPromise());
  };
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    StateModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MiscellaneousModule,
    ReactiveFormsModule,
    IconsProviderModule,
    CodemirrorModule,
    NzLayoutModule,
    NzMenuModule,
    LangSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
  ],
  providers: [
    {provide: NZ_I18N, useValue: en_US},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authenticate,
      deps: [Store, LanguageService, PlatformService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },

    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
    NzModalService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
