// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  logging: true,
  baseUrl: 'https://iot-backend.gpo-tech.com/',
  ws_url: 'wss://iot-backend.gpo-tech.com/',
  //baseUrl: 'http://localhost:8080/',
  adminCompanyId: 1,
  sentry_dsn: 'https://42c921f1d1514488b7f62ad6f2bc0325@o4504040143585280.ingest.sentry.io/4504088964956160',
  api_url: ['https://iot-frontend.gpo-tech.com'],
  mode: 'prod',
  defaultTimeZone: 'Europe/Kiev',
  baseUserAvatarImage: 'assets/img/avatar.png',
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZ3Bvd29yayIsImEiOiJjbDc0b3MwbDcwZHU2M3FwZXVkYzRhbnN6In0.3bCUn3VY4UFNR5DAkcgLcw'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
