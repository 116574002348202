import {
  CompaniesInfo,
  ICompany,
  ICompanyPage,
  ICompanyPicker,
  ICompanyPostPutDTO,
  ICompanyQueryParams
} from './company.model';


export namespace CompanyActions {

  export class InfoFetched {
    static readonly type = '[Company] info fetched';

    constructor(public info: CompaniesInfo) {
    }
  }

  export class FetchCompanyPage {
    static readonly type = '[Company] fetch company page';

    constructor(public page: number, public params: ICompanyQueryParams) {
    }
  }

  export class CompanyPageFetched {
    static readonly type = '[Company] Company page fetched';

    constructor(public page: ICompanyPage, public params: ICompanyQueryParams) {
    }
  }

  export class FetchCompaniesPicker {
    static readonly type = '[Company] fetch companies picker';

    constructor(public name: string = '') {
    }
  }

  export class CompaniesPickerFetched {
    static readonly type = '[Company] companies picker fetched';

    constructor(public companies: ICompanyPicker[]) {
    }
  }

  export class SelectCompanyPicker {
    static readonly type = '[Company] select company picker';

    constructor(public companyId: ICompanyPicker['id']) {
    }
  }

  export class SortCompaniesInPicker {
    static readonly type = '[Company] sort companies in picker';
  }

  export class ResetFilters {
    static readonly type = '[Company] reset filters';
  }

  export class CreateCompany {
    static readonly type = '[Company] create company';

    constructor(public company: ICompanyPostPutDTO, public logo?: File) {
    }
  }

  export class CreateCompanyPublic {
    static readonly type = '[Company] create company from public page';

    constructor(public company: ICompanyPostPutDTO, public code: string, public logo?: File) {
    }
  }

  export class UpdateCompany {
    static readonly type = '[Company] update company';

    constructor(public company: ICompanyPostPutDTO, public logo?: File) {
    }
  }

  export class RemoveCompany {
    static readonly type = '[Company] remove company';

    constructor(public companyId: ICompany['id']) {
    }
  }

  export class RemoveCompanyLogo {
    static readonly type = '[Company] remove company logo';

    constructor(public companyId: ICompany['id']) {
    }
  }
}

