import {Injectable} from '@angular/core';
import {
  ForgotPassFinishCredentials,
  ForgotPassInitCredentials,
  IRegisterCompanyDTO,
  JwtToken,
  LoginCredentials,
  RegisterCredentials
} from './auth.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {RequestService} from '../../core/services/request.service';
import {map} from 'rxjs/operators';
import {UserModel} from '../../core/models/user.model';
import {HttpHelperService} from "../../core/services/http.service";


@Injectable({
  providedIn: 'root',
})
export class AuthService extends RequestService {
  private readonly loginUrl = this.createUrl('api/authenticate');
  private readonly registerUrl = this.createUrl('api/register');
  private readonly forgotPassInitUrl = this.createUrl('api/account/reset-password/init');
  private readonly forgotPassFinishUrl = this.createUrl('api/account/reset-password/finish');
  private readonly authenticateUrl = this.createUrl('api/authenticate');
  private readonly accountUrl = this.createUrl('api/account');
  private readonly accountActivateUrl = this.createUrl('api/activate');
  private readonly accountActivateEndUserUrl = this.createUrl('api/activate/end-user');
  private readonly updatePasswordUrl = this.createUrl('api/account/change-password');
  private readonly refreshTokenUrl = this.createUrl('api/authenticate/refresh');
  private readonly getCompanyNameUrl = this.createUrl('api/vendor/v1/creating-key/info');

  constructor(
    private httpClient: HttpClient,
    private httpHelperService: HttpHelperService
  ) {
    super();
  }

  login(credentials: LoginCredentials): Observable<JwtToken> {
    return this.httpClient.post<JwtToken>(this.loginUrl, credentials);
  }

  register(credentials: RegisterCredentials): Observable<JwtToken> {
    return this.httpClient.post<JwtToken>(this.registerUrl, credentials);
  }

  forgotPassInit(credentials: ForgotPassInitCredentials): Observable<unknown> {
    return this.httpClient.post(this.forgotPassInitUrl, credentials.email);
  }

  forgotPassFinish(credentials: ForgotPassFinishCredentials): Observable<unknown> {
    return this.httpClient.post(this.forgotPassFinishUrl, credentials);
  }

  authenticate(): Observable<boolean> {
    return this.httpClient.get<boolean>(this.authenticateUrl, { responseType: 'text' as any })
      .pipe(map(login => !!login));
  }

  getProfile(): Observable<UserModel> {
    return this.httpClient.get<UserModel>(this.accountUrl)
  }

  activateAccount(key: string): Observable<any> {
    const url: string = this.accountActivateUrl + `?key=${key}`;

    return this.httpClient.get(url);
  }

  accountActivateEndUser(key: string): Observable<any> {
    const url: string = this.accountActivateEndUserUrl + `?key=${key}`;

    return this.httpClient.get(url);
  }

  getCompanyName(code: string): Observable<IRegisterCompanyDTO> {
    const params = this.httpHelperService.createRequestParams({code});
    return this.httpClient.get<IRegisterCompanyDTO>(this.getCompanyNameUrl, {params});
  }

  updatePassword(payload: {currentPassword: string, newPassword: string}): Observable<any> {
    return this.httpClient.post(this.updatePasswordUrl, payload);
  }


  refreshToken(token: string): Observable<JwtToken> {
    return this.httpClient.post<JwtToken>(this.refreshTokenUrl, token);
  }
}
