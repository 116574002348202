import {NgModule} from '@angular/core';

import {MiscellaneousRoutingModule} from './miscellaneous-routing.module';
import {MiscellaneousComponent} from './miscellaneous.component';
import {NotFoundComponent} from './not-found/not-found.component';

@NgModule({
  imports: [
    MiscellaneousRoutingModule,
  ],
  declarations: [
    MiscellaneousComponent,
    NotFoundComponent,
  ],
})
export class MiscellaneousModule {
}
