import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, NEVER, Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {AuthActions} from '../../store/auth/auth.action';
import {AuthState} from '../../store/auth/auth.state';
import {AuthService} from '../../store/auth/auth.service';



@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private store: Store,
    private authService: AuthService
  ) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 && this.store.selectSnapshot(AuthState.token)) {
            return this.handle401Error(request, next);
            }

          return throwError(error);
        }),
      );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken(this.store.selectSnapshot(AuthState.refreshToken))
        .pipe(
          catchError(() => {
            this.isRefreshing = false;
            this.store.dispatch(new AuthActions.Logout());
            return NEVER;
          }),
          switchMap((response) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(response.id_token);
            this.store.dispatch(new AuthActions.TokenRefreshed(response));
            return next.handle(this.addToken(request, response.id_token));
          }),
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
