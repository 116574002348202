import { Injectable } from '@angular/core';

enum Platform {
  Android = 'Android',
  iOS = 'iOS',
  MacOS = 'Mac OS',
  Windows = 'Windows',
  Linux = 'Linux',
}

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private userAgent = window.navigator.userAgent;
  private platform = window.navigator.platform;
  private macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  private windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  private iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  os: Platform = null;

  constructor() {}

  get isMobile(): boolean {
    return [ Platform.Android, Platform.iOS ].includes(this.os);
  }

  get isiOS(): boolean {
    return this.os === Platform.iOS;
  }

  get isAndroid(): boolean {
    return this.os === Platform.Android;
  }

  get isFireFox(): boolean {
    return window.navigator.userAgent.match(/Firefox\/([0-9]+)\./) !== null;
  }

  get isSafari(): boolean {
    return navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0;
  }

  get isDesktop(): boolean {
    return [ Platform.Windows, Platform.MacOS, Platform.Linux ].includes(this.os);
  }

  setOS(): void {
    if (this.macosPlatforms.indexOf(this.platform) !== -1) {
      this.os = Platform.MacOS;
    } else if (this.iosPlatforms.indexOf(this.platform) !== -1) {
      this.os = Platform.iOS;
    } else if (this.windowsPlatforms.indexOf(this.platform) !== -1) {
      this.os = Platform.Windows;
    } else if (/Android/.test(this.userAgent)) {
      this.os = Platform.Android;
    } else if (!this.os && /Linux/.test(this.platform)) {
      this.os = Platform.Linux;
    }
    this.displayConsole();
  }

  private displayConsole(): void {
    console.log(`%c [PLATFORM]: `, 'color: green', this.os);
  }
}
