import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzModalService} from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {

 constructor(
   private translateService: TranslateService,
   private notificationService: NzNotificationService,
   private modalService: NzModalService,
 ) {
 }

  public showErrorMessage(e: any): void {
    const title = e.error && e.error.title ?
      this.getErrTitle(e.error.title, e.error.path) :
      this.translateService.instant('messages.error');

    const message = e.error && e.error.detail ?
      this.getErrMessage(e.error.detail, e.error.path) :
      this.getErrMessage(e.statusText);

    if (title && message) {
      this.notificationService.error(title, message, {nzClass: 'notification_error'});
    }
  }

  public getErrTitle(title: string, url?: string): string {
    if (!title) {
      return null;
    }

    if (title.includes('Not Found') && (url.includes('device') || url.includes('companies'))) {
      return this.translateService.instant('messages.error');

    } else if (['Unauthorized'].includes(title)) {
      return this.translateService.instant(`messages.${title.toLowerCase()}`);

    } else if (title === 'Email is already in use!') {
      return this.translateService.instant('messages.email-is-used');

    } else if (title === 'Login name already used!') {
      return this.translateService.instant('messages.login-is-used');

    } else if (title === 'Method argument not valid') {
      return this.translateService.instant('messages.login-is-missed');

    } else if (title.includes('Device is never connected to server')) {
      return this.translateService.instant('messages.device-never-connected');

    } else if (title.includes('Device not found in ChirpStack.')) {
      return this.translateService.instant('messages.device-not-found');

    } else if (title.includes('Company not active.')) {
      return this.translateService.instant('messages.company-deactivated');

    } else if (title.includes('Oops, something went wrong.')) {
      return this.translateService.instant('messages.eui-in-use');

    } else if (title.includes('Internal Server Error')) {
      return this.translateService.instant('messages.internal-server-error');

    } else if (
      title.includes('Device has incompatible type with this group.') ||
      title.includes('Some of devices has incompatible type with this group.')
    ) {
      return this.translateService.instant('messages.device-not-added-to-group');

    } else if (title.includes('Requested company not found.')) {
      return this.translateService.instant('messages.user-not-register');

    }  else if (title.includes('Incorrect password')) {
      return this.translateService.instant('messages.incorrect-password');

    } else if (title.includes('Requested company name is present.')) {
      return this.translateService.instant('messages.company-already-exist');

    } else if (title.includes('Not active license for this type of device.')) {
      return this.translateService.instant('messages.no-active-license-present');

    } else if (title.includes('Requested license not found.')) {
      return this.translateService.instant('messages.license-not-found');

    }
    return title;
  }

  public  getErrMessage(message: string, url?: string): string {
    if (!message) {
      return null;
    }

    if (message.includes('404 NOT_FOUND') && url.includes('device')) {
      const entity = this.translateService.instant('entity.device');
      return this.translateService.instant('messages.missing-entity', { entity });

    } else if (message.includes('404 NOT_FOUND') &&  url.includes('companies')) {
      return this.translateService.instant('messages.company-not-found');

    } else if (['Bad credentials', 'Bad Request', 'Not Found'].includes(message)) {
      return this.translateService.instant(`messages.${message.toLowerCase().replace(' ', '_')}`);

    } else if (message.includes('not activated')) {
      return this.translateService.instant('messages.user_not_activated');

    } else if (message.toLowerCase().includes('forbidden')) {
      return this.translateService.instant('messages.forbidden');

    } else if (message.toLowerCase().includes('дані користувача некоректні')) {
      return this.translateService.instant('messages.invalid-credentials');

    } else if (
      message.includes('Device has incompatible type with this group.') ||
      message.includes('Some of devices has incompatible type with this group.')
    ) {
      return this.translateService.instant('messages.invalid-group-type');

    } else if (message.includes('JDBCConnectionException')) {
      return this.translateService.instant('messages.server-unreachable');

    } else if (message.includes('Conflict'))  {
      return this.translateService.instant('messages.error');

    }
    return message;
  }

  showErrorInModal(error: string): void {
    this.modalService.error({
      nzTitle: error,
    });
  }
}
