import { FirmwareAssignmentDTO, FirmwareDTO, FirmwareUploadDTO, IFirmwareFetchQueryParams } from "./firmware.model";

export namespace FirmwareActions {

  export class FetchAllFirmwares {
    static readonly type = '[Firmware] fetch all firmware';

    constructor(
      public page: number,
      public params?: IFirmwareFetchQueryParams) {
    }
  }

  export class FetchAllAssigneeFirmware {
    static readonly type = '[Firmware] fetch all assignee firmware';

    constructor(
      public page: number,
      public params?: IFirmwareFetchQueryParams) {
    }
  }

  export class AllFirmwaresFetched {
    static readonly type = '[Firmware] Fetched all firmwares'

    constructor(
      public allFirmwares: FirmwareDTO[]) {
    }
  }

  export class PageSizeChange {
    static readonly type = '[Firmware] Assign firmware page size';

    constructor(
      public size: number,
      public params?: IFirmwareFetchQueryParams) {
    }
  }

  export class AssigneeFirmwarePageSizeChange {
    static readonly type = '[Firmware]  Page size';

    constructor(
      public size: number,
      public params?: IFirmwareFetchQueryParams) {
    }
  }

  export class UploadFirmware {
    static readonly type = '[Firmware] Upload firmware';

    constructor(
      public firmware: FirmwareUploadDTO) {
    }
  }

  export class DeleteFirmware {
    static readonly type = '[Firmware] Delete firmware';

    constructor(
      public id: number) {
    }
  }

  export class AssignFirmware {
    static readonly type = '[Firmware] Assign firmware';

    constructor(
      public payload: FirmwareAssignmentDTO) {
    }
  }

  export class UnAssignFirmware {
    static readonly type = '[Firmware] Unassign firmware';

    constructor(
      public payload: FirmwareAssignmentDTO) {
    }
  }

  export class ForceBurnFirmware {
    static readonly type = '[Firmware] Force burn firmware';

    constructor(
      public payload: FirmwareAssignmentDTO) {
    }
  }

}
