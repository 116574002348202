import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/markdown/markdown';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from "@sentry/angular";
import {BrowserTracing} from "@sentry/tracing";
import SentryRRWeb from "@sentry/rrweb";

// Sentry.init({
//   dsn: environment.sentry_dsn,
//   integrations: [
//     new BrowserTracing({
//         tracingOrigins: environment.api_url,
//         routingInstrumentation: Sentry.routingInstrumentation
//       }
//     ),
//     new SentryRRWeb({
//       checkoutEveryNms: 5 * 60 * 1000
//     }),
//   ],
//   tracesSampleRate: 1.0
// });

if (environment.production) {
  enableProdMode();

}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
