import { LoadableStateModel } from "../../core/models/loadable.model";
import { PageableStateModel } from "../../core/models/pageable.model";
import {
  DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_ASSIGN,
  DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_UPLOAD,
  FirmwareAssignmentInfoDTO,
  FirmwareDTO,
  IFirmwareFetchQueryParams
} from "./firmware.model";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { StoreState } from "../store.state";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { ServerHealthyService } from "../../core/services/server-healthy.service";
import { ErrorMessageService } from "../../core/services/error-message.service";
import { FirmwareActions } from "./firmware.action";
import { FirmwareService } from "./firmware.service";
import { first } from "rxjs/operators";
import { Pagination } from "../../core/models/request";


export interface FirmwareStateModel extends LoadableStateModel, PageableStateModel {
  firmwares: FirmwareDTO[];
  allFirmwares: FirmwareDTO[];
  assigneeFirmwares: FirmwareAssignmentInfoDTO[];
  fetchQueryParams?: IFirmwareFetchQueryParams;
  fetchQueryParamsAssign?: IFirmwareFetchQueryParams;
  firmwareUploadStatus: boolean;
}

@State<FirmwareStateModel>({
  name: 'firmwares',
  defaults: {
    firmwares: [],
    firmwareUploadStatus: false,
    allFirmwares: [],
    assigneeFirmwares: [],
    total: 0,
    page: 1,
    size: 25,
    loading: false,
    fetchQueryParams: DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_UPLOAD,
    fetchQueryParamsAssign: DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_ASSIGN
  }
})

@Injectable({ "providedIn": 'root' })
export class FirmwareState extends StoreState<FirmwareStateModel> {
  constructor(
    protected notificationService: NzNotificationService,
    protected translateService: TranslateService,
    protected serverHealthyService: ServerHealthyService,
    protected errorMessageService: ErrorMessageService,
    protected firmwareService: FirmwareService,
    protected nzNotificationService: NzNotificationService
  ) {
    super(notificationService, translateService, serverHealthyService, errorMessageService);
  }

  @Selector()
  static loading(state: FirmwareStateModel) {
    return state.loading;
  }

  @Selector()
  static firmwares(state: FirmwareStateModel) {
    return state.firmwares;
  }

  @Selector()
  static firmwareUploadStatus(state: FirmwareStateModel) {
    return state.firmwareUploadStatus;
  }

  @Selector()
  static allFirmwares(state: FirmwareStateModel) {
    return state.allFirmwares;
  }

  @Selector()
  static assignedFirmwares(state: FirmwareStateModel) {
    return state.assigneeFirmwares;
  }

  @Selector()
  static total(state: FirmwareStateModel) {
    return state.total;
  }

  @Selector()
  static pagination(state: FirmwareStateModel) {
    return { page: state.page, size: state.size } as Pagination;
  }

  @Action(FirmwareActions.FetchAllFirmwares)
  fetchAllFirmware(ctx: StateContext<FirmwareStateModel>, { page, params }: FirmwareActions.FetchAllFirmwares) {
    const { size, fetchQueryParams } = ctx.getState();
    ctx.patchState({
      loading: true,
      fetchQueryParams: { ...fetchQueryParams }
    });
    this.firmwareService.fetchAllFirmware({ page, size }, params).pipe(
      first()
    ).subscribe(
      response => {
        const { fetchQueryParams } = ctx.getState();
        ctx.patchState({
          fetchQueryParams: { sort: fetchQueryParams.sort, ...params },
          firmwares: response.firmwares,
          total: response.total,
          firmwareUploadStatus: false,
          loading: false
        });
      },
      (e) => this.errorHandler(e, ctx)
    );
  }

  @Action(FirmwareActions.FetchAllAssigneeFirmware)
  fetchAllAssigneeFirmware(ctx: StateContext<FirmwareStateModel>, {
    page,
    params
  }: FirmwareActions.FetchAllAssigneeFirmware) {
    const { size, fetchQueryParams } = ctx.getState();
    ctx.patchState({
      loading: true,
      fetchQueryParams: { ...fetchQueryParams }
    });
    this.firmwareService.fetchAllAssigneeFirmware({ page, size }, params).pipe(
      first()
    ).subscribe(
      response => {
        const { fetchQueryParams } = ctx.getState();
        ctx.patchState({
          fetchQueryParams: { sort: fetchQueryParams.sort, ...params },
          assigneeFirmwares: response.firmwaresAssignment,
          total: response.total,
          loading: false
        });
      },
      (e) => this.errorHandler(e, ctx)
    );
  }


  @Action(FirmwareActions.AllFirmwaresFetched)
  fetchedAllFirmwares({ patchState }: StateContext<FirmwareStateModel>, payload: FirmwareActions.AllFirmwaresFetched) {
    patchState({ allFirmwares: payload.allFirmwares, loading: false })
  }

  @Action(FirmwareActions.AssignFirmware)
  assignFirmware(ctx: StateContext<FirmwareStateModel>, { payload }: FirmwareActions.AssignFirmware) {
    ctx.patchState({ loading: true });
    this.firmwareService.assignFirmware(payload).pipe(
      first()
    ).subscribe(
      () => {
        ctx.patchState({ loading: false });
        ctx.dispatch(new FirmwareActions.FetchAllAssigneeFirmware(1, DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_ASSIGN));
        this.nzNotificationService.success(this.translateService.instant('FIRMWARE.ASSIGN_SUCCESS'), 'FIRMWARE.ASSIGN_SUCCESS');
      },
      (e) => this.errorHandler(e, ctx)
    );
  }

  @Action(FirmwareActions.PageSizeChange)
  pageSize(ctx: StateContext<FirmwareStateModel>, { size, params }: FirmwareActions.PageSizeChange) {
    ctx.patchState({ size, page: 1, loading: true });
    this.firmwareService.fetchAllFirmware({ page: 1, size }, params)
      .pipe(first())
      .subscribe(
        response => {
          ctx.patchState({
            firmwares: response.firmwares,
            total: response.total,
            loading: false
          });
        },
        (e) => this.errorHandler(e, ctx)
      );
  }

  @Action(FirmwareActions.AssigneeFirmwarePageSizeChange)
  assigneeFirmwaresPageSize(ctx: StateContext<FirmwareStateModel>, { size, params }: FirmwareActions.PageSizeChange) {
    ctx.patchState({ size, page: 1, loading: true });
    this.firmwareService.fetchAllAssigneeFirmware({ page: 1, size }, params)
      .pipe(first())
      .subscribe(
        response => {
          ctx.patchState({
            assigneeFirmwares: response.firmwaresAssignment,
            total: response.total,
            loading: false
          });
        },
        (e) => this.errorHandler(e, ctx)
      );
  }

  @Action(FirmwareActions.UploadFirmware)
  uploadFirmware(ctx: StateContext<FirmwareStateModel>, { firmware }: FirmwareActions.UploadFirmware) {
    ctx.patchState({ loading: true });
    this.firmwareService.uploadFirmware(firmware).pipe(
      first()
    ).subscribe(
      () => {
        ctx.patchState({ loading: false , firmwareUploadStatus: true});
        ctx.dispatch(new FirmwareActions.FetchAllFirmwares(1, DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_UPLOAD));
        this.notificationService.success('Success', `${firmware.file.name} File uploaded successfully`);
      },
      (e) => this.errorHandler(e, ctx)
    );
  }

  @Action(FirmwareActions.DeleteFirmware)
  deleteFirmware(ctx: StateContext<FirmwareStateModel>, { id }: FirmwareActions.DeleteFirmware) {
    ctx.patchState({ loading: true });
    this.firmwareService.deleteFirmware(id).pipe(
      first()
    ).subscribe(
      () => {
        ctx.patchState({ loading: false });
        ctx.dispatch(new FirmwareActions.FetchAllFirmwares(1, DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_UPLOAD));
      },
      (e) => this.errorHandler(e, ctx)
    );
  }

  @Action(FirmwareActions.UnAssignFirmware)
  unAssignFirmware(ctx: StateContext<FirmwareStateModel>, { payload }: FirmwareActions.UnAssignFirmware) {
    ctx.patchState({ loading: true });
    this.firmwareService.unAssignFirmware(payload).pipe(
      first()
    ).subscribe(
      () => {
        ctx.patchState({ loading: false });
        ctx.dispatch(new FirmwareActions.FetchAllAssigneeFirmware(1, DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_ASSIGN));
        this.nzNotificationService.success(this.translateService.instant('FIRMWARE.UNASSIGN_SUCCESS'), 'FIRMWARE.UNASSIGN_SUCCESS');
      },
      (e) => this.errorHandler(e, ctx)
    );
  }

  @Action(FirmwareActions.ForceBurnFirmware)
  forceBurnFirmware(ctx: StateContext<FirmwareStateModel>, { payload }: FirmwareActions.ForceBurnFirmware) {
    ctx.patchState({ loading: true });
    this.firmwareService.forceBurnFirmware(payload).pipe(
      first()
    ).subscribe(
      () => {
        ctx.patchState({ loading: false });
        this.nzNotificationService.success(this.translateService.instant('messages.success'), this.translateService.instant('messages.force-burn-firmware'));
      },
      (e) => this.errorHandler(e, ctx)
    );
  }

}
