<nz-select
  [(ngModel)]="selectedLang"
  (ngModelChange)="changeLanguage($event)"
  [nzDisabled]="autofocusDisableFix">
  <nz-option
    [nzValue]="'en'"
    [nzLabel]="'languages.en' | translate"></nz-option>
  <nz-option
    [nzValue]="'uk'"
    [nzLabel]="'languages.uk' | translate"></nz-option>
  <nz-option
    [nzValue]="'ru'"
    [nzLabel]="'languages.ru' | translate"></nz-option>
</nz-select>
