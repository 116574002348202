import { Injectable } from "@angular/core";
import { RequestService } from "../../core/services/request.service";
import { HttpClient } from "@angular/common/http";
import { HttpHelperService } from "../../core/services/http.service";
import { Pagination } from "../../core/models/request";
import {
  FirmwareAssignmentDTO,
  FirmwareAssignmentInfoDTO,
  FirmwareAssignmentPage,
  FirmwareDTO,
  FirmwarePage,
  IFirmwareFetchQueryParams
} from "./firmware.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class FirmwareService extends RequestService {
  constructor(
    private httpClient: HttpClient,
    private httpHelperService: HttpHelperService
  ) {
    super();
  }

  private readonly fetchAllFirmwareUrl = this.createUrl('api/gpo/v1/firmware/filter');
  private readonly fetchAllAssigneeFirmwareUrl = this.createUrl('api/gpo/v1/firmware/assignment/filter');
  private readonly assignFirmwareUrl = this.createUrl('api/gpo/v1/firmware/assign');
  private readonly unAssignFirmwareUrl = this.createUrl('api/gpo/v1/firmware/unassign');
  private readonly uploadFirmwareUrl = this.createUrl('api/gpo/v1/firmware');
  private readonly deleteFirmwareUrl = (id: FirmwareDTO['id']) => `${this.createUrl('api/gpo/v1/firmware')}/${id}`;
  private readonly forceBurnFirmwareUrl = (
    assigneeType: FirmwareAssignmentDTO['assigneeType'],
    assigneeId: FirmwareAssignmentDTO['assigneeId'],
    thingType: FirmwareAssignmentDTO['thingType']
  ) => `${this.createUrl('api/gpo/v1/firmware')}/${assigneeType}/${assigneeId}/${thingType}/force-burn`;

  fetchAllFirmware(pagination: Pagination, firmwareFetchQueryParams: IFirmwareFetchQueryParams): Observable<FirmwarePage> {
    pagination.page--;

    const params = this.httpHelperService.createRequestParams({ ...firmwareFetchQueryParams, ...pagination });

    return this.httpClient
      .get<FirmwarePage>(this.fetchAllFirmwareUrl, {
        params,
        observe: 'response'
      }).pipe(
        map(response => {
          return {
            firmwares: (response.body || []) as FirmwareDTO[],
            total: Number(response.headers.get('X-Total-Count')) || 0
          };
        })
      );
  }

  fetchAllAssigneeFirmware(pagination: Pagination, firmwareFetchQueryParams: IFirmwareFetchQueryParams): Observable<FirmwareAssignmentPage> {
    pagination.page--;

    const params = this.httpHelperService.createRequestParams({ ...firmwareFetchQueryParams, ...pagination });

    return this.httpClient
      .get<FirmwareAssignmentPage>(this.fetchAllAssigneeFirmwareUrl, {
        params,
        observe: 'response'
      }).pipe(
        map(response => {
          return {
            firmwaresAssignment: (response.body || []) as FirmwareAssignmentInfoDTO[],
            total: Number(response.headers.get('X-Total-Count')) || 0
          };
        })
      );
  }

  uploadFirmware(payload): Observable<void> {
    const formData = new FormData();
    formData.append('thingType', payload.thingType);
    formData.append('version', payload.version);
    formData.append('description', payload.description);
    formData.append('file', payload.file);
    return this.httpClient.post<void>(this.uploadFirmwareUrl, formData);
  }

  deleteFirmware(id: number): Observable<void> {
    return this.httpClient.delete<void>(this.deleteFirmwareUrl(id));
  }

  assignFirmware(payload: FirmwareAssignmentDTO): Observable<void> {
    return this.httpClient.post<void>(this.assignFirmwareUrl, payload);
  }

  unAssignFirmware(payload: FirmwareAssignmentDTO): Observable<void> {
    return this.httpClient.post<void>(this.unAssignFirmwareUrl, payload);
  }

  forceBurnFirmware(payload: FirmwareAssignmentDTO): Observable<void> {
    return this.httpClient.post<void>(this.forceBurnFirmwareUrl(payload.assigneeType, payload.assigneeId, payload.thingType), {});
  }
}
