export enum Language {
  EN = 'en',
  UK = 'uk',
  RU = 'ru',
}

export enum DeviceStatus {
  PENDING = 'PENDING',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  WARNING = 'WARNING',
  REGISTERED = 'REGISTERED'
}

export const DeviceStatusArray: string[] = ['NEW', 'REGISTERED', 'ASSIGNED', 'SUSPENDED', 'DISABLED']

export enum ShowOnlineStatus {
  ALL = '',
  ONLINE = 'true',
  OFFLINE = 'false',
  ALL_ONLINE = 'all_online',
}

export enum StatusColor {
  GREEN = 'green',
  BLUE = 'blue',
  GOLD = 'gold',
  ORANGE = 'orange',
  RED = 'red',
  CYAN = 'cyan',
}

export enum ThingStatus {
  NEW = "NEW",
  REGISTERED = "REGISTERED",
  ASSIGNED = "ASSIGNED",
  SUSPENDED = "SUSPENDED",
  DISABLED = "DISABLED"
}

export enum LocalStorageKeys {
  DEVICES_PARAMS = 'devicesParams',
  DEVICES_FILTERS_FOR_MAP = 'devicesFiltersForMap',
}
