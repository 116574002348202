import {Injectable} from '@angular/core';
import {Language} from '../const/types';
import * as moment from 'moment';
import {registerLocaleData} from '@angular/common';

import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';
import localeUk from '@angular/common/locales/uk';
import {TranslateService} from '@ngx-translate/core';
import {en_US, NzI18nService, ru_RU, uk_UA} from 'ng-zorro-antd/i18n';
import {Subject} from 'rxjs';
import {LANGUAGE} from '../const/const';


@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public langChangedEmitter$ = new Subject<Language>();

  private readonly commonLocales = {
    [Language.EN]: localeEn,
    [Language.RU]: localeRu,
    [Language.UK]: localeUk,
  };

  private readonly ngLocales = {
    [Language.EN]: en_US,
    [Language.RU]: ru_RU,
    [Language.UK]: uk_UA,
  };

  constructor(
    private translateService: TranslateService,
    private i18n: NzI18nService
  ) {

  }

  updateTranslations(): void {
    this.ngLocales[Language.UK].DatePicker.lang.today = this.translateService.instant('buttons.today');
    this.ngLocales[Language.RU].DatePicker.lang.today = this.translateService.instant('buttons.today');
    this.ngLocales[Language.EN].DatePicker.lang.today = this.translateService.instant('buttons.today');
    this.ngLocales[Language.UK].Pagination.page = this.translateService.instant('pagination.page');
  }

  get currLang(): Language {
    return this.translateService.currentLang as Language;
  }

  initLanguage(lang?: string): Promise<void> {
    const storageLanguage = localStorage.getItem(LANGUAGE);
    this.translateService.setDefaultLang(Language.EN);
    const appLang: string = lang ? lang : storageLanguage ? storageLanguage : this.getDeviceLang();
    return this.changeLanguage(appLang as Language);
  }

  async changeLanguage(lang: Language): Promise<void> {
    if (!(lang in this.commonLocales)) {
      lang = Language.EN;
    }

    try {
      await this.translateService.use(lang).toPromise();
    } catch (error) {
      console.error(error);
    }

    moment.locale(lang);
    registerLocaleData(this.commonLocales[lang]);
    this.langChangedEmitter$.next(lang);
    console.log('%c [APP LANGUAGE]: ', 'color: green', lang);
    localStorage.setItem(LANGUAGE, lang);
    this.updateTranslations();
    this.i18n.setLocale(this.ngLocales[lang]);
    return Promise.resolve();
  }

  getDeviceLang(): string {
    const languages: string[] = Object.values(Language);

    for (const lang of languages) {
      if (navigator.languages[0].toLowerCase().includes(lang.toLowerCase())) {
        return lang;
      }
    }

    return Language.EN;
  }
}
