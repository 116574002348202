import {LoadableStateModel} from '../../core/models/loadable.model';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {CompanyService} from './company.service';
import {PageableStateModel} from '../../core/models/pageable.model';
import {StoreState} from '../store.state';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';
import {CompanyActions} from './company.action';
import {CompaniesInfo, Feature, ICompanyQueryParams, MapboxOutput} from './company.model';
import {catchError, first, map, skip, switchMap, tap} from 'rxjs/operators';
import {ICompany, ICompanyPicker} from './company.model';
import {environment} from 'src/environments/environment';
import {ServerHealthyService} from 'src/app/core/services/server-healthy.service';
import {Pagination} from '../../core/models/request';
import {cloneDeep, isEqual} from 'lodash';
import {Observable, throwError} from 'rxjs';
import {DEFAULT_COMPANY_STATE} from '../default-state.model';
import {ErrorMessageService} from '../../core/services/error-message.service';
import {DevicesActions} from "../panel/panels.action";

export interface CompanyStateModel extends LoadableStateModel, PageableStateModel {
  companiesPicker: ICompanyPicker[];
  companies: ICompany[];
  showCompanyPicker: boolean;
  allowSelectAll: boolean;
  companyPageLoading: boolean;
  queryParams: ICompanyQueryParams;
  defaultQueryParams: ICompanyQueryParams;
  selectedCompanyId?: ICompanyPicker['id'];
  info?: CompaniesInfo;
}

@State<CompanyStateModel>({
  name: 'company',
  defaults: {
    ...DEFAULT_COMPANY_STATE,
  },
})
@Injectable({providedIn: 'root'})
export class CompanyState extends StoreState<CompanyStateModel> {

  constructor(
    private companyService: CompanyService,
    protected notificationService: NzNotificationService,
    protected translateService: TranslateService,
    protected serverHealthyService: ServerHealthyService,
    private store: Store,
    protected errorMessageService: ErrorMessageService,
  ) {
    super(notificationService, translateService, serverHealthyService, errorMessageService);

    this.translateService.onLangChange
      .pipe(skip(1))
      .subscribe(() => this.store.dispatch(new CompanyActions.SortCompaniesInPicker()));
  }

  @Selector()
  static loading(state: LoadableStateModel) {
    return state.loading;
  }

  @Selector()
  static companyPageLoading(state: CompanyStateModel) {
    return state.companyPageLoading;
  }

  @Selector()
  static companyId(state: CompanyStateModel) {
    return state.selectedCompanyId;
  }

  @Selector()
  static allowSelectAll(state: CompanyStateModel) {
    return state.allowSelectAll;
  }

  @Selector()
  static companiesPicker(state: CompanyStateModel) {
    return state.companiesPicker;
  }

  @Selector()
  static pickedCompany(state: CompanyStateModel) {
    return state.companiesPicker.find(company => state.selectedCompanyId === company.id);
  }

  @Selector()
  static companies(state: CompanyStateModel) {
    return state.companies;
  }

  @Selector()
  static info(state: CompanyStateModel) {
    return state.info;
  }

  @Selector()
  static showCompanyPicker(state: CompanyStateModel) {
    return state.showCompanyPicker;
  }

  @Selector()
  static queryParams(state: CompanyStateModel) {
    return state.queryParams;
  }

  @Selector()
  static defaultParams(state: CompanyStateModel) {
    return state.defaultQueryParams;
  }

  @Selector()
  static isDefaultFilters(state: CompanyStateModel) {
    return isEqual(state.queryParams, state.defaultQueryParams);
  }

  @Selector()
  static total(state: CompanyStateModel) {
    return state.total;
  }

  @Selector()
  static pagination(state: CompanyStateModel) {
    return {page: state.page, size: state.size} as Pagination;
  }

  @Action(CompanyActions.InfoFetched)
  private async loaded({patchState}: StateContext<CompanyStateModel>, {info}: CompanyActions.InfoFetched) {
    patchState({info, loading: false});
  }

  @Action(CompanyActions.SelectCompanyPicker)
  private async selectCompany({patchState}: StateContext<CompanyStateModel>, {companyId}: CompanyActions.SelectCompanyPicker) {
    localStorage.setItem('selectedCompanyId', companyId ? companyId.toString() : null);
    patchState({selectedCompanyId: companyId});
  }

  @Action(CompanyActions.FetchCompaniesPicker)
  private fetchCompaniesPicker(ctx: StateContext<CompanyStateModel>, {name}: CompanyActions.FetchCompaniesPicker) {
    return this.companyService.getCompaniesPicker(name)
      .pipe(
        switchMap(res => ctx.dispatch(new CompanyActions.CompaniesPickerFetched(res))),
        catchError(err => {
          this.errorHandler(err, ctx);
          return throwError(err);
        }),
      );
  }

  @Action(CompanyActions.CompaniesPickerFetched)
  private companiesPickerFetched({patchState}: StateContext<CompanyStateModel>, {companies}: CompanyActions.CompaniesPickerFetched) {
    const selectedCompanyId = JSON.parse(localStorage.getItem('selectedCompanyId'));
    const state: Partial<CompanyStateModel> = {
      companiesPicker: this.getSortedCompanies(companies),
      showCompanyPicker: true,
      selectedCompanyId,
      loading: false
    };

    const hasGPOCompany: boolean = companies.map(company => company.id).includes(environment.adminCompanyId)
    if (hasGPOCompany) {
      state.allowSelectAll = true;
    } else {
      if (state.selectedCompanyId === null) {
        state.selectedCompanyId = companies[0] ? companies[0].id : null;
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const queryCompanyId = Number(urlParams.get('companyId'));

    if (companies.map(company => company.id).includes(queryCompanyId)) {
      state.selectedCompanyId = queryCompanyId;
      localStorage.setItem('selectedCompanyId', queryCompanyId.toString());
    } else if (hasGPOCompany && isNaN(queryCompanyId)) {
      state.selectedCompanyId = null;
    }

    patchState(state);
  }

  @Action(CompanyActions.FetchCompanyPage)
  private fetchCompanyPage(ctx: StateContext<CompanyStateModel>, {page, params}: CompanyActions.FetchCompanyPage) {
    ctx.patchState({companyPageLoading: true});
    const {size} = ctx.getState();

    this.companyService.getCompanies({page, size: size ? size : 10} as Pagination, params)
      .pipe(first())
      .subscribe(
        response => ctx.dispatch(new CompanyActions.CompanyPageFetched(response, params)),
        (e) => this.errorHandler(e, ctx),
      );
  }

  @Action(CompanyActions.CompanyPageFetched)
  private companyPageFetched(ctx: StateContext<CompanyStateModel>, {page, params}: CompanyActions.CompanyPageFetched) {
    ctx.patchState({...page, queryParams: params, companyPageLoading: false});
  }

  @Action(CompanyActions.CreateCompany)
  createCompany(ctx: StateContext<CompanyStateModel>, {company, logo}: CompanyActions.CreateCompany): Observable<any> {
    const {queryParams} = ctx.getState();
    return this.companyService.createCompany(company, logo)
      .pipe(
        tap((resultCompany) => {
          const companiesPicker: ICompanyPicker[] = cloneDeep(ctx.getState().companiesPicker);
          companiesPicker.push({id: resultCompany.id, name: resultCompany.name});
          ctx.patchState({companiesPicker: this.getSortedCompanies(companiesPicker)});
          this.store.dispatch(new CompanyActions.FetchCompanyPage(1, queryParams));
        }),
        catchError(error => {
          this.errorHandler(error);
          return throwError(error);
        }),
      );
  }

  @Action(CompanyActions.CreateCompanyPublic)
  createCompanyPublicPage(ctx: StateContext<CompanyStateModel>, {
    company,
    code,
    logo
  }: CompanyActions.CreateCompanyPublic): Observable<any> {
    return this.companyService.createCompanyPublic(company, code, logo)
      .pipe(
        catchError(error => {
          this.errorHandler(error);
          return throwError(error);
        }),
      );
  }

  @Action(CompanyActions.UpdateCompany)
  updateCompany(ctx: StateContext<CompanyStateModel>, {company, logo}: CompanyActions.UpdateCompany): Observable<any> {
    const {page, queryParams} = ctx.getState();
    const request = logo ?
      this.companyService.updateCompanyAndLogo(company, logo) :
      this.companyService.updateCompany(company);

    return request
      .pipe(
        tap(() => {
          const companiesPicker: ICompanyPicker[] = cloneDeep(ctx.getState().companiesPicker);
          const index: number = companiesPicker.findIndex(comp => comp.id === company.id);
          if (index !== -1) {
            companiesPicker[index].name = company.name;
            ctx.patchState({companiesPicker});
          }
          this.store.dispatch(new CompanyActions.FetchCompanyPage(page, queryParams));
        }),
        tap(() => {
          this.notificationService.success(
            this.translateService.instant('notification.success'),
            this.translateService.instant('messages.company-updated'),
          );
        }),
        catchError(error => {
          this.errorHandler(error);
          return throwError(error);
        }),
      );
  }

  @Action(CompanyActions.RemoveCompany)
  removeCompany(ctx: StateContext<CompanyStateModel>, {companyId}: CompanyActions.RemoveCompany): Observable<any> {
    ctx.patchState({companyPageLoading: true});
    const {queryParams} = ctx.getState();
    return this.companyService.removeCompany(companyId)
      .pipe(
        tap(() => {
          const companiesPicker: ICompanyPicker[] = cloneDeep(ctx.getState().companiesPicker);
          const index: number = companiesPicker.findIndex(company => company.id === companyId);
          if (index !== -1) {
            companiesPicker.splice(index, 1);
          }

          const patchedState: { [key: string]: any } = {companiesPicker};
          if (ctx.getState().selectedCompanyId === companyId) {
            patchedState.selectedCompanyId = companiesPicker[0].id;
          }

          ctx.patchState(patchedState);
          this.store.dispatch(new CompanyActions.FetchCompanyPage(1, queryParams));
        }),
        catchError(error => {
          this.errorHandler(error);
          ctx.patchState({companyPageLoading: false});
          return throwError(error);
        }),
      );
  }

  @Action(CompanyActions.RemoveCompanyLogo)
  removeCompanyLogo(ctx: StateContext<CompanyStateModel>, {companyId}: CompanyActions.RemoveCompanyLogo): Observable<any> {
    return this.companyService.removeCompanyLogo(companyId)
      .pipe(
        catchError(error => {
          this.errorHandler(error);
          return throwError(error);
        }),
      );
  }

  @Action(CompanyActions.SortCompaniesInPicker)
  sortCompaniesInPicker(ctx: StateContext<CompanyStateModel>): void {
    const {companiesPicker} = ctx.getState();
    ctx.patchState({companiesPicker: this.getSortedCompanies(companiesPicker)});
  }

  private getSortedCompanies(companies: ICompanyPicker[]): ICompanyPicker[] {
    return cloneDeep(companies).sort((a, b) => {
      return a.name.localeCompare(b.name, this.translateService.currentLang, {sensitivity: 'base'});
    });
  }
}
