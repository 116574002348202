import {
  ForgotPassFinishCredentials,
  ForgotPassInitCredentials,
  JwtToken,
  LoginCredentials,
  RegisterCredentials
} from './auth.model';


export namespace AuthActions {
  export class Authenticate {
    static readonly type = '[Auth] Authenticate';
  }

  export class Login {
    static readonly type = '[Auth] Login';

    constructor(public payload: LoginCredentials) {
    }
  }



  export class LoggedIn {
    static readonly type = '[Auth] Logged in';

    constructor() {
    }
  }

  export class Logout {
    static readonly type = '[Auth] Logout';
  }

  export class Register {
    static readonly type = '[Auth] Register';

    constructor(public payload: RegisterCredentials) {
    }
  }

  export class ForgotPassInit {
    static readonly type = '[Auth] ForgotPassInit';

    constructor(public payload: ForgotPassInitCredentials) {
    }
  }

  export class ForgotPassInitSuccess {
    static readonly type = '[Auth] ForgotPassInitSuccess';

    constructor() {
    }
  }

  export class ForgotPassFinish {
    static readonly type = '[Auth] ForgotPassFinish';

    constructor(public payload: ForgotPassFinishCredentials) {
    }
  }

  export class ForgotPassFinishEndUser {
    static readonly type = '[Auth] ForgotPassFinishEndUser';

    constructor(public payload: ForgotPassFinishCredentials) {
    }
  }

  export class UpdatePassword {
    static readonly type = '[Auth] UpdatePassword';

    constructor(public newPassword: string, public oldPassword: string) {
    }
  }

  export class GetCompanyName {
    static readonly type = '[Auth] GetCompanyName';

    constructor(
      public code: string) {
    }
  }

  export class PasswordUpdated {
    static readonly type = '[Auth] PasswordUpdated';

    constructor(public token: string) {
    }
  }

  export class TokenRefreshed {
    static readonly type = '[Auth] TokenRefreshed';

    constructor(public data: JwtToken) {
    }
  }


}

