import {CompanyStatus} from "../../core/models/company.models";

export interface CompaniesInfo {
  active: number;
  pending: number;
}

export interface ICompanyQueryParams {
  sort: string[];
  name?: string;
  ownerId?: number;
  status?: CompanyStatus;
}

export interface ICompanyPage {
  companies: ICompany[];
  total: number;
  page: number;
}

export interface ICompany {
  actualAddress: string;
  addedOn: string;
  description: string;
  enableNotifications: boolean;
  id: number;
  legalAddress: string;
  logo: any;
  name: string;
  notes: string;
  owner: IDeviceUser;
  status: string;
  phone: string;
}

export interface ICompanyPostPutDTO {
  name: string;
  legalAddress: string;
  phone: string;
  id?: number;
  actualAddress?: string;
  description?: string;
  notes?: string;
  logoId?: number;
  status?: CompanyStatus;
  user: {
    email: string;
    firstName: string;
    lastName: string;
    langKey: string;
    phone: string;
  };
}

export interface ICompanyPicker {
  id: number;
  name: string;
}

export interface IDeviceUser {
  activated: boolean;
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  imageUrl: string;
  langKey: string;
  login: string;
  profile: any;
  resetDate: any;
  resetKey: any;
  phone: string;
}

export const DEFAULT_FILTERS: ICompanyQueryParams = {
  sort: ['addedOn,desc'],
};

export enum ImgSize {
  Size75X75= 'SIZE_75X75',
  Size150X150 = 'SIZE_150X150',
  Size480X320 = 'SIZE_480X320',
  Size1024X768 = 'SIZE_1024X768',
  SizeOriginal = 'SIZE_ORIGINAL',
}

export interface MapboxOutput {
  attribution: string;
  features: Feature[];
  query: []
}

export interface Feature {
  place_name: string;
  center: number[];
}
