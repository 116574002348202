import {Injectable} from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from '../../store/auth/auth.state';
import {tap} from 'rxjs/operators';
import {AuthActions} from '../../store/auth/auth.action';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private store: Store, private router: Router) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.store.select(AuthState.loggedIn)
      .pipe(
        tap(isLoggedIn => {
          if (!isLoggedIn) {
            this.store.dispatch(new AuthActions.Logout());
          }
        }),
      );
  }

}
