import {AuthActions} from './auth.action';
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {LANGUAGE, REFRESH_TOKEN, TOKEN} from '../../core/const/const';
import {AuthService} from './auth.service';
import {Injectable, NgZone} from '@angular/core';
import {catchError, delay, first, map, mapTo, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable, of} from 'rxjs';
import {StoreState} from '../store.state';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {ServerHealthyService} from 'src/app/core/services/server-healthy.service';
import {UserModel} from '../../core/models/user.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorMessageService} from '../../core/services/error-message.service';
import {LoadableStateModel} from "../../core/models/loadable.model";
import {CompanyActions} from "../company/company.action";
import {IRegisterCompanyDTO} from "./auth.model";
import {StorageHelper} from '../../core/utils/localStorageHelper';


export interface AuthStateModel extends LoadableStateModel {
  token: string;
  refreshToken: string;
  rememberMe: boolean;
  profile: UserModel;
  authenticated: boolean;
  companyName: IRegisterCompanyDTO
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    refreshToken: null,
    loading: false,
    rememberMe: true,
    profile: null,
    authenticated: false,
    companyName: null
  },
})
@Injectable()
export class AuthState extends StoreState<AuthStateModel> implements NgxsOnInit {

  private readonly PUBLIC_PATHS: string[] = [
    'create-company',
    'registration',
    'activate-account',
    'forgot-password',
    'quick-controller'
  ];

  constructor(
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private ngZone: NgZone,
    private store: Store,
    private route: ActivatedRoute,
    protected notificationService: NzNotificationService,
    protected translateService: TranslateService,
    protected serverHealthyService: ServerHealthyService,
    protected errorMessageService: ErrorMessageService,
    protected storageHelper: StorageHelper
  ) {
    super(notificationService,
      translateService, serverHealthyService,
      errorMessageService);
  }

  ngxsOnInit({ patchState }: StateContext<AuthStateModel>) {
    const tokens = this.storageHelper.getItems([TOKEN, REFRESH_TOKEN], true);

    if (!!tokens) {
      patchState({ token: tokens[TOKEN], refreshToken: tokens[REFRESH_TOKEN] });
    }
  }


  @Selector()
  static loading(state: LoadableStateModel) {
    return state.loading;
  }

  @Selector()
  static loggedIn(state: AuthStateModel) {
    return state.authenticated;
  }

  @Selector()
  static token(state: AuthStateModel) {
    return state.token;
  }

  @Selector()
  static refreshToken(state: AuthStateModel) {
    return state.refreshToken;
  }

  @Selector()
  static profile(state: AuthStateModel) {
    return state.profile;
  }

  @Selector()
  static companyName(state: AuthStateModel) {
    return state.companyName;
  }

  @Action(AuthActions.Authenticate)
  authenticate(ctx: StateContext<AuthStateModel>): Observable<boolean> {
    const token = this.store.selectSnapshot(AuthState.token);
    if (!token) {
      return this.store.dispatch(new AuthActions.Logout());
    }

    ctx.patchState({loading: true});

    return this.authService.authenticate()
      .pipe(
        switchMap(isAuthenticated => {
          if (!isAuthenticated) {
            return of(false);
          }
          return this.loadUserData$(ctx);
        }),
        tap(isAuthenticated => {
          if (!isAuthenticated) {
            ctx.patchState({loading: false});
            this.store.dispatch(new AuthActions.Logout());
          } else {
            ctx.patchState({loading: false, authenticated: true});
          }
        }),
        catchError(error => {
          console.log('Error occurred while checking for authentication', error);
          if (ctx.getState().token && error.status !== 401) {
            ctx.patchState({loading: false, authenticated: true});
            this.router.navigate(['server-unreachable']);
          } else {
            ctx.patchState({loading: false});
            this.store.dispatch(new AuthActions.Logout());
          }
          return of(false);
        }),
      );
  }


  @Action(AuthActions.Login, { cancelUncompleted: true })
  login(ctx: StateContext<AuthStateModel>, action: AuthActions.Login): Observable<any> {
    ctx.patchState({rememberMe: action.payload.rememberMe, loading: true});
    return this.authService.login({
      username: action.payload.username,
      password: action.payload.password,
      rememberMe: action.payload.rememberMe,
    })
      .pipe(
        switchMap(jwtToken => {
          const {id_token, refreshToken} = jwtToken;
          ctx.patchState({token: id_token, refreshToken: refreshToken});
          this.storageHelper.setItem(
            {
              [TOKEN]: id_token,
              [REFRESH_TOKEN]: refreshToken
            },
            action.payload.rememberMe
          );

          return this.store.dispatch(new AuthActions.Authenticate());
        }),
        tap(() => {
          const authenticated: boolean = this.store.selectSnapshot(AuthState.loggedIn);
          if (authenticated) {
            ctx.dispatch(new AuthActions.LoggedIn());
          }
        }),
        catchError(error => {
          this.handleLoginError(error, ctx);
          return of(false);
        }),
      );
  }

  @Action(AuthActions.LoggedIn)
  loggedIn(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({authenticated: true});
    this.ngZone.run(() => {
      this.router.navigate(['/pages', 'dashboard'])
        .then(() => ctx.patchState({loading: false}))
        .catch(() => ctx.patchState({loading: false}));
    });
  }

  @Action(AuthActions.Register)
  register(ctx: StateContext<AuthStateModel>, action: AuthActions.Register) {
    ctx.patchState({rememberMe: action.payload.rememberMe, loading: true});

    this.authService.register(action.payload)
      .pipe(
        tap(() => ctx.patchState({loading: false})),
        switchMap(() => of(this.ngZone.run(() => this.router.navigate(['/auth', 'login'])))),
        delay(100),
        first(),
      ).subscribe(
      () => {
        this.notificationService.success(
          this.translate.instant('messages.success-register'),
          this.translate.instant('notification.success'),
        );
      },
      (e) => {
        const showKeyFailedMsg = e instanceof HttpErrorResponse && e.status === 404 && e.error.message === 'error.creatingKeyFound';
        if (showKeyFailedMsg) {
          this.errorMessageService.showErrorInModal(this.translateService.instant('messages.register-key-failed'));
          ctx.patchState({ loading: false });
        } else {
          this.errorHandler(e, ctx);
        }
      },
    );
  }

  @Action(AuthActions.Logout)
  logout({patchState}: StateContext<AuthStateModel>) {
    patchState({authenticated: false, token: null});
    const lang = localStorage.getItem(LANGUAGE);
    localStorage.clear();
    localStorage.setItem(LANGUAGE, lang);
    this.goToLoginPage();
  }

  @Action(AuthActions.ForgotPassInit)
  forgotPassInit(ctx: StateContext<AuthStateModel>, action: AuthActions.ForgotPassInit): void {
    ctx.patchState({loading: true});
    this.authService.forgotPassInit(action.payload)
      .subscribe(
        () => {
          ctx.dispatch(new AuthActions.ForgotPassInitSuccess());
          ctx.patchState({loading: false});
        },
        (e) => this.errorHandler(e, ctx),
      );
  }

  @Action(AuthActions.ForgotPassFinish)
  forgotPassFinish(ctx: StateContext<AuthStateModel>, action: AuthActions.ForgotPassFinish): void {
    ctx.patchState({loading: true});
    this.authService.forgotPassFinish(action.payload)
      .subscribe(
        () => {
          ctx.patchState({loading: false});
          this.ngZone.run(() => this.router.navigate(['/auth', 'login']));
          this.notificationService.success(
            this.translate.instant('messages.success-password-updated'),
            this.translate.instant('notification.success'),
          );
        },
        (e) => this.errorHandler(e, ctx),
      );
  }

  @Action(AuthActions.ForgotPassFinishEndUser)
  enduserforgotPassFinish(ctx: StateContext<AuthStateModel>, action: AuthActions.ForgotPassFinishEndUser): void {
    ctx.patchState({loading: true});
    this.authService.forgotPassFinish(action.payload)
      .subscribe(
        () => {
          ctx.patchState({loading: false});
          this.ngZone.run(() => this.router.navigate(['/enduser', 'forgot-password-account', 'updated-success']));
          this.notificationService.success(
            this.translate.instant('messages.success-password-updated'),
            this.translate.instant('notification.success'),
          );
        },
        (e) => this.errorHandler(e, ctx),
      );
  }

  @Action(AuthActions.GetCompanyName)
  getCompanyName(ctx: StateContext<AuthStateModel>, {code}: AuthActions.GetCompanyName): void {
    ctx.patchState({loading: true});
    this.authService.getCompanyName(code)
      .subscribe(
        (companyName) => {
          ctx.patchState({loading: false, companyName});
        },
        (e) => this.errorHandler(e, ctx),
      );
  }



  @Action(AuthActions.UpdatePassword)
  updatePassword(ctx: StateContext<AuthStateModel>,
                 {newPassword, oldPassword}: AuthActions.UpdatePassword): Observable<any> {
    ctx.patchState({loading: true});

    const {rememberMe, profile: {login}} = ctx.getState();

    return this.authService.updatePassword({currentPassword: oldPassword, newPassword})
      .pipe(
        switchMap(() => {
          return this.authService.login({username: login, password: newPassword})
            .pipe(
              map(jwtToken => {
                const {id_token, refreshToken} = jwtToken;
                this.storageHelper.setItem(
                  {
                    [TOKEN]: id_token,
                    [REFRESH_TOKEN]: refreshToken
                  },
                  rememberMe
                );
                return id_token;
              })
            );
        }),
        tap((token) => ctx.dispatch(new AuthActions.PasswordUpdated(token))),
        catchError(error => {
          this.errorHandler(error, ctx);
          return of(null);
        }),
      );
  }


  @Action(AuthActions.TokenRefreshed)
  tokenRefreshed(ctx: StateContext<AuthStateModel>, {data}: AuthActions.TokenRefreshed): void {
    const {rememberMe} = ctx.getState();
    this.storageHelper.setItem(
      {
        [TOKEN]: data.id_token,
        [REFRESH_TOKEN]: data.refreshToken
      },
      rememberMe
    );
    ctx.patchState({token: data.id_token, refreshToken: data.refreshToken});
  }


  @Action(AuthActions.PasswordUpdated)
  async passwordUpdated(ctx: StateContext<AuthStateModel>, {token}: AuthActions.PasswordUpdated) {
    this.notificationService.success(
      this.translateService.instant('notification.success'),
      this.translateService.instant('messages.password-updated'),
    );
    ctx.patchState({loading: false, token});
  }

  private handleLoginError(error: HttpErrorResponse, ctx: StateContext<AuthStateModel>): void {
    console.log('Error occured while log in', error);
    this.store.dispatch(new AuthActions.Logout());
    this.errorHandler(error, ctx);
  }


  private goToLoginPage(): void {
    let canGoToLogin: boolean = true;
    this.PUBLIC_PATHS.forEach(path => {
      if (window.location.href.includes(path)) {
        canGoToLogin = false;
      }
    });

    if (canGoToLogin) {
      console.log('Go to login page');
      this.ngZone.run(() => this.router.navigate(['/auth', 'login']));
    }
  }

  private loadCompaniesPicker$(): Observable<boolean> {
    return this.store.dispatch(new CompanyActions.FetchCompaniesPicker())
      .pipe(
        mapTo(true),
        catchError(error => {
          console.log('Error occurred while loading user companies', error);
          this.errorHandler(error);
          return of(false);
        }),
      );
  }

  private loadUserData$(ctx: StateContext<AuthStateModel>): Observable<boolean> {
    return this.authService.getProfile()
      .pipe(
        switchMap(profile => {
          ctx.patchState({profile});
          return forkJoin([
            this.loadCompaniesPicker$(),
          ])
            .pipe(
              map((responses: boolean[]) => {
                return !responses.includes(false);
              }),
              switchMap(isLoaded => {
                if (!isLoaded) {
                  return of(false);
                }
                return of(true);
              }),
            );
        }),
        catchError(error => {
          console.log('Error occurred while loading user profile', error);
          this.errorHandler(error);
          return of(false);
        }),
      );
  }
}
