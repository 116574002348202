import {IUser} from "../user/user.model";

export interface FirmwareDTO {
  id: number;
  thingType: ThingType;
  version: string;
  description: string;
  addedBy: IUser;
  addedAt: Date;
  fileUrl: string;
  fileSize: number;
}

export interface IFirmwareFetchQueryParams {
  thingType?: ThingType;
  serial?: string;
  version?: string;
  sort?: string[];
  id?: string;
}


export interface FirmwarePage {
  firmwares: FirmwareDTO[];
  total: number;
}

export interface FirmwareAssignmentPage {
  firmwaresAssignment: FirmwareAssignmentInfoDTO[];
  total: number;
}

export enum ThingType {
  PORTABLE_ELECTRIC_HEATER = 'PORTABLE_ELECTRIC_HEATER',
  SOCKET = 'SOCKET',
}

export interface FirmwareUploadDTO {
  thingType: ThingType;
  version: string;
  description: string;
  file: File;
}

export interface FirmwareAssignmentDTO {
  assigneeType: AssigneeType;
  assigneeId: number;
  firmwareId?: number;
  thingType: ThingType;
}

export interface FirmwareAssignmentInfoDTO {
  assigneeType: AssigneeType;
  assigneeId: number;
  assigneeName: string;
  version: string;
  description: string;
  addedOn: Date;
  addedBy: IUser;
  thingType: ThingType;
}


export enum AssigneeType {
  COMPANY = 'COMPANY',
  BUILDING = 'BUILDING',
  GROUP = 'GROUP',
  THING = 'THING'
}

export const DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_UPLOAD = {
  sort: ['addedAt,desc'],
};

export const DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_ASSIGN = {
  sort: ['assignedOn,desc'],
};
